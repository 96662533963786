<i18n lang="json">
{
	"ru": {
		"showGoldPartnerLabelAbility": "Золотой партнер",
		"showSilverPartnerLabelAbility": "Серебряный партнер",
    "superhost": "Суперхозяин",
    "favoriteAdd": "Добавить в избранное",
    "favoriteRemove": "Убрать из избранного",
    "meter": "м<sup><small>2</small></sup>",
    "recommended": "Гости рекомендуют",
    "cashback": "30% Кэшбэк"
	},
	"en": {
		"showGoldPartnerLabelAbility": "Gold partner",
		"showSilverPartnerLabelAbility": "Silver partner",
    "superhost": "Superhost",
    "favoriteAdd": "Add to favorites",
    "favoriteRemove": "Remove from favorites",
    "meter": "m<sup><small>2</small></sup>",
    "recommended": "Guest's choice",
    "cashback": "30% Cashback"
	}
}
</i18n>

<template>
  <div class="carousel">
    <router-link
      :to="{
        name: 'Detail',
        params: { id: data.id },
        query: routerQuery,
      }"
      v-slot="{ href }"
      custom
    >
      <a
        target="_blank"
        :href="href"
        @mousedown="clickObjectBronevik($event, linkBronevik)"
      >
        <BaseCardMapSlider
          :media="data.media || []"
          @activeOtherPhotosAvailableSlide="hideFavorite = $event"
        />
        <BaseFavorite
          v-if="!whitelabel && !hideFavorite"
          :id="Number(data.id)"
          ref="favorite"
          class="carousel__favorite"
          :is-favorite="data.favorite"
          @favoriteState="setFavorite"
        />
      </a>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import SliderLight from "@sutochno/sliderlight";
import Rating from "@/components/base/BaseRating.vue";
import BaseCardMapSlider from "@/components/base/BaseCardMapSlider.vue";
import BaseFavorite from "@/components/base/BaseFavorite.vue";
import { useI18n } from 'vue-i18n';

export default {
  components: {
    SliderLight,
    Rating,
    BaseCardMapSlider,
    BaseFavorite
  },
  props: {
    data: {
      type: Object,
    },
    linkBronevik: {
      type: String,
      default: "",
    },
    isBronevik: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFavorite: this.data.favorite,
      hideFavorite: false
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  methods: {
    setFavorite(payload) {
      this.isFavorite = payload;
    },
    eventCloseCard() {
      this.$store.commit("search/setCardVisible", false);
      this.$store.commit("search/setSelectedMapPointId", null);
    },
    clickObjectBronevik(event, link) {
      if (link || (link && event.which == 1) || (link && event.which == 2)) {
        event.preventDefault();
        window.open(link);
        sendEventGtag("search_sa", {
          click: "object_b_link",
          value: this.data.location.city.title,
        });
      }
    },
  },
  computed: {
    ...mapState("user", ["isAuth", "userData", "whitelabel"]),
    ...mapState("search", ["guests"]),
    ...mapGetters("search", ["occupied", "getQuery"]),
    partnerLabel() {
      return this.data.owner.permissions.find(
        (v) =>
          v == "showGoldPartnerLabelAbility" ||
          v == "showSilverPartnerLabelAbility"
      );
    },
    message() {
      return this.isFavorite === true
        ? this.t("favoriteRemove")
        : this.t("favoriteAdd");
    },
    routerQuery() {
      const query = {
        occupied: this.occupied,
        guests_adults: this.guests.adults,
        guests_childrens: this.guests.childrens.join(','),
        id: this.getQuery.query.id,
        type: this.getQuery.query.type,
        term: this.getQuery.query.term,
      };

      if (this.data.hot_sale && this.data.hot_sale.sale) {
        return { ...query, burning_offer: 1 };
      }
      return query;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  background: #fff;
  position: relative;
  height: 100%;
  z-index: 0;
  > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  &:hover {
    :deep(.sc-sliderlight__arrows.arrows) {
      opacity: 1;
      button {
        z-index: 100;
      }
    }
  }
  img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  :deep(.VueCarousel) {
    // height: 100%;
    .VueCarousel-slide {
      height: inherit;
    }
    .VueCarousel-inner {
      max-height: 100%;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &:hover
    :deep(.VueCarousel-navigation-button.VueCarousel-navigation-next::before) {
    right: 0;
    margin: 0 0 0 auto;
    background-position: -44px 0;
  }
  &:hover :deep(.VueCarousel-navigation-button::before) {
    display: block;
    content: "";
    background-image: url("~/assets/img/spr_ss_arrows.png");
    width: 44px;
    height: 44px;
  }
  & :deep(.VueCarousel-navigation-button) {
    outline: none !important;
    top: 0;
    bottom: 0;
    margin: 0;
    width: 50%;
    transform: translateY(0) translateX(0%);
  }

  &__size {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 2;

    border: 1px solid #fff;
    border-radius: 2px;
    color: #fff;
    padding: 2px 8px;
    font-size: 12px;
    line-height: 16px;
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 15px;
    height: 15px;
    z-index: 2;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: 7px;
    content: " ";
    height: 15px;
    width: 2px;
    background-color: #fff;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  &__favorite {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 2;
  }
}

//фикс бага свайпа слайдера на карте
:deep(.sc-sliderlight) {
  pointer-events: none;

  .arrows__btn-wrapp--left {
    pointer-events: auto;
  }
  .arrows__btn-wrapp--right {
    pointer-events: auto;
  }
}
</style>
