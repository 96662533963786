<i18n lang="json">
{
  "ru": {
    "cert_description": "Звёздность отеля подтверждена официальным сертификатом",
  },
  "en": {
    "cert_description": "The star rating of the hotel is confirmed by official certificate",
  }
}
</i18n>

<template>
  <span class="hotel-stars__container">
    <span
      class="hotel-stars__stars-count"
      v-if="starsCountChecked && isCert"
    >
      {{ starsCountChecked }}
    </span>
    <span
      class="icon-app-star2 hotel-stars__star"
      v-if="starsCountChecked && isCert"
    ></span>
    <span
      class="icon-app-cert hotel-stars__cert"
      v-if="isCert && showCertIcon"
      @mouseenter="showPopup = true"
      @mouseleave="showPopup = false"
    >
      <div class="cert-popup-arrow" v-show="showPopup"></div>
      <div class="cert-popup" v-show="showPopup">
        {{ t("cert_description") }}
      </div>
    </span>
  </span>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  starsCount: {
    type: [Number],
    default: 0,
    validator: (val) => val >= 0 && val <= 5,
  },
  starSize: {
    type: Number,
    default: 14,
  },
  countSize: {
    type: Number,
    default: 22,
  },
  certSize: {
    type: Number,
    default: 22,
  },
  isCert: {
    type: Boolean,
    default: false,
  },
  showCertIcon: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const certData = computed(() => store.getters["detail/starCert"]);

const showPopup = ref(false);

const starFontSize = computed(() => `${props.starSize}px`);
const countFontSize = computed(() => `${props.countSize}px`);
const certFontSize = computed(() => `${props.certSize}px`);

const starsCountChecked = computed(() => {
  if(props.starsCount >= 0 && props.starsCount <= 5){
    return props.starsCount
  }
  return null
})

</script>

<style scoped lang="scss">
.hotel-stars {
  &__container {
    margin-right: 2px;
  }
  &__stars-count {
    font-size: v-bind(countFontSize);
    margin-right: 2px;
  }
  &__star {
    font-size: v-bind(starFontSize);
    position: relative;
    bottom: calc(75% - v-bind(starFontSize));
  }
  &__cert {
    font-size: v-bind(certFontSize);
    margin-left: 2px;
    position: relative;
    cursor: pointer;
  }
}

.cert-popup-arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #444444;
  transform: rotate(45deg) translate(7px, 10px);
}
.cert-popup {
  position: absolute;
  padding: 15px;
  min-width: 280px;
  background-color: #444444;
  color: #ffffff;
  transform: translate(-50%, 20px);
  z-index: 1000;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 425px) {
  .hotel-stars__container {
    margin-right: 4px;
  }

  .cert-popup-arrow,
  .cert-popup {
    display: none;
  }
}
</style>
