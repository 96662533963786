<template>
  <div class="detail">
    <div class="detail-slider-toolbar">
      <span
        class="toolbar-icon-close icon-app-cross-min"
        @click="closeDetail()"
      ></span>
    </div>
  </div>
</template>
<script>
import { documentBody } from "@sutochno/utils";
export default {
  name: "ObjectDetail",
  props: {
    id: {
      type: String,
      required: true,
    },
    showDetail: {
      required: true,
    },
  },
  unmounted() {
    documentBody.overflow("");
  },
  methods: {
    closeDetail() {
      this.$parent.showDetail = !this.showDetail;
      documentBody.overflow("");
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999999999;
  overflow: auto;
  .detail-desktop {
    min-height: 100%;
  }
}
.detail-slider-toolbar {
  text-align: right;
  height: 40px;
  background: #f6f6f6;
  position: fixed;
  right: 20px;

  .toolbar-icon-close {
    right: 0;
    color: #717171;
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    height: 40px;
    display: inline-block;
    &:hover {
      color: #333;
    }
  }
}
</style>
