<i18n lang="json">
{
  "ru": {
    "guestChoice":"Выбор гостей",
    "adults":"Взрослые",
    "children17yearsOld":"Дети младше 18 лет",
    "childrensAge":"Возраст детей:"
  },
  "en": {
    "guestChoice":"Guest Choice",
    "adults":"Adults",
    "children17yearsOld":"Children under the age of 18",
    "childrensAge":"Childrens age:"
  }
}
</i18n>
<template>
  <div class="select-guest">
    <div class="guest">
      <div class="guest--name">
        {{ t("adults") }}
      </div>
      <div class="guest--count">
        <button class="minus" @click="adultsBtn('minus')"></button>
        <input v-model.number="adults" type="number" @change="changeAdults" />
        <button
          :class="{ disabled: allGuests >= maxGuestsObject }"
          class="plus"
          @click="adultsBtn('plus')"
        ></button>
      </div>
    </div>
    <div class="guest" :class="{ disabled: maxGuestsObject == 1 }">
      <div class="guest--name">
        {{ t("children17yearsOld") }}
      </div>
      <div class="guest--count">
        <button class="minus" @click="childrensBtn('minus')"></button>
        <input
          v-model="countChildrens"
          type="number"
          placeholder="0"
          @input="changeChildrens"
        />
        <button
          :class="{ disabled: allGuests >= maxGuestsObject }"
          class="plus"
          @click="childrensBtn('plus')"
        ></button>
      </div>
    </div>
    <div v-if="childrens.length" class="childrens">
      <div class="childrens--name">
        {{ t("childrensAge") }}
      </div>
      <div class="childrens--count">
        <SelectAge
          v-for="(item, index) in childrens.length"
          :key="index"
          v-model="selectAge"
          :index="index"
          :selected="childrens[index]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectAge from "./SelectAge";
import _ from "lodash";
import { useI18n } from 'vue-i18n';

export default {
  name: "SelectMain",
  components: {
    SelectAge,
  },
  props: {
    guests: {
      type: Object,
    },
    maxGuestsObject: {
      type: Number,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      countChildrens: "",
      childrens: [],
      maxChildrens: 9,
      adults: 1,
      selectAge: null,
    };
  },
  computed: {
    allGuests() {
      return this.adults + this.childrens.length;
    },
  },
  watch: {
    "guests.adults"(val) {
      this.adults = JSON.parse(JSON.stringify(val));
    },
    "guests.childrens"(val) {
      this.childrens = JSON.parse(JSON.stringify(val));
    },
    selectAge(val) {
      if (this.guests.childrens.length > 0) {
        for (let i = 0; i < this.guests.childrens.length; i++) {
          if (val.index == i && this.guests.childrens[i] != val.val) {
            this.childrens[val.index] = val.val;
            this.emitGuests(val);
          }
        }
      }
    },
  },
  mounted() {
    const guests = JSON.parse(JSON.stringify(this.guests));
    this.adults = guests.adults;
    this.childrens = guests.childrens;
    this.countChildrens = this.childrens.length ? this.childrens.length : "";
  },
  methods: {
    changeAdults() {
      if (this.adults > 50) {
        this.adults = 50;
      }
      if (this.adults == "") {
        this.adults = 1;
      }
      if (this.adults > this.maxGuestsObject - this.childrens.length) {
        this.adults = this.maxGuestsObject - this.childrens.length;
      }
      if (this.guests.adults != this.adults) {
        this.emitGuests();
      }
    },
    changeChildrens() {
      if (parseInt(this.countChildrens) > 9) {
        this.countChildrens = 9;
      }
      if (+this.countChildrens == 0) {
        this.countChildrens = "";
        for (let i = this.childrens.length; i > 0; i--) {
          this.childrens.splice(i - 1, 1);
        }
      }
      if (this.maxGuestsObject) {
        if (
          parseInt(this.countChildrens) >
          this.maxGuestsObject - this.adults
        ) {
          this.countChildrens = this.maxGuestsObject - this.adults;
        }
      }
      if (this.countChildrens != "") {
        for (let i = 0; i < +this.countChildrens; i++) {
          if (!this.childrens[i]) {
            this.childrens.push(10);
          }
        }
        if (parseInt(this.countChildrens) < this.childrens.length) {
          for (let i = this.childrens.length; i > +this.countChildrens; i--) {
            this.childrens.splice(i - 1, 1);
          }
        }
      }
      this.emitGuests();
    },
    adultsBtn(val) {
      if (val == "minus") {
        this.adults--;
        if (this.adults <= 1) {
          this.adults = 1;
        }
      } else if (val == "plus" && this.adults < 50) {
        this.adults++;
      }
      this.emitGuests();
      // if (this.guests.adults != this.adults) {
      // }
    },
    childrensBtn(val) {
      if (val == "minus") {
        this.countChildrens--;
        if (this.countChildrens <= 0) {
          this.countChildrens = "";
        }
        this.childrens.splice(
          this.countChildrens == "" ? 0 : this.countChildrens
        );
      } else if (val == "plus") {
        if (this.countChildrens < this.maxChildrens) {
          this.countChildrens++;
          this.childrens.push(10);
        }
      }
      this.emitGuests();
    },
    emitGuests: _.debounce(function (val) {
      this.countChildrens = this.childrens.length ? this.childrens.length : "";
      if (val) {
        this.$emit("update:modelValue", {
          adults: this.adults,
          childrens: this.childrens,
          all: this.allGuests,
          age: {
            index: val.index,
            val: val.val,
          },
        });
      } else {
        this.$emit("update:modelValue", {
          adults: this.adults,
          childrens: this.childrens,
          all: this.allGuests,
        });
      }
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.select-guest {
  background: #fff;
  .guest {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    padding: 0 20px;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &--name {
      font-size: 15px;
      color: #444444;
      line-height: 1;
    }
    &--count {
      display: flex;
      align-items: center;
      button {
        display: block;
        width: 18px;
        height: 18px;
        cursor: pointer;
        outline-style: none;
        margin: 0;
        border: none;
        background: none;
        position: relative;
        &:after {
          content: "";
          width: 12px;
          height: 2px;
          position: absolute;
          top: 8px;
          left: 3px;
          background: #717171;
        }
        &.plus {
          &:before {
            content: "";
            width: 2px;
            height: 12px;
            position: absolute;
            top: 3px;
            left: 8px;
            background: #717171;
          }
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      input[type="text"],
      input[type="number"] {
        display: block;
        outline-style: none;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        width: 32px;
        text-align: center;
        color: #444444;
        font-size: 15px;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        // &:hover,
        // &:focus {
        //   -moz-appearance: number-input;
        // }
      }
    }
  }
  .childrens {
    padding: 14px 0 10px 20px;
    background: #ededed;
    &--name {
      font-size: 13px;
      color: #444444;
      margin-bottom: 8px;
    }
    &--count {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 230px;
    }
  }
}
</style>
