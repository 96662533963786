<i18n>
{
  "ru": {
    "sightsAirport": "Ближайший аэропорт",
    "sightsStation": "Ближайший ж/д вокзал"
  },
  "en": {
    "sightsAirport": "Nearest airport",
    "sightsStation": "Nearest railway station"
  }
}
</i18n>

<template>
  <div :class="{ 'modal-wrap-list': modalMapView }" v-if="isSights">
    <div
      v-if="!modalMapView"
      class="map-sights-padding"
      :class="{ active: isActivePoint }"
    ></div>
    <div class="map-sights">
      <div class="map-sights--col" v-for="(item, index) in sights" :key="index">
        <div class="map-sights--title">
          {{ item.title }}
        </div>
        <MapSelectPoint
          :list-points="item.list"
          :index-sights="index"
          :active-point="item.activePoint"
          :modal-map-view="modalMapView"
          @setRoute="setRoute"
          @setActivePoint="setActivePoint($event, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MapSelectPoint from "./MapSelectPoint.vue";
import { mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "MapSights",
  props: {
    modalMapView: {
      type: Boolean,
    },
    activePointRopewayMetro: {
      type: Number,
    },
  },
  components: {
    MapSelectPoint,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    }
  },
  data() {
    return {
      sights: [],
    };
  },
  computed: {
    ...mapState("detail", ["distanceSights"]),
    isActivePoint() {
      return this.sights.filter((item) => item.activePoint !== undefined).length;
    },
    isSights() {
      const sights = this.distanceSights?.sights || [];
      return sights.length;
    },
  },
  mounted() {
    this.getDistanceSights();
  },
  watch: {
    modalMapView(val) {
      if (!val) {
        this.clearActivePoint();
      }
    },
    activePointRopewayMetro(val) {
      if (val !== undefined) {
        this.clearActivePoint();
      }
    },
    distanceSights(val) {
      if (val) {
        this.getDistanceSights();
      }
    },
    isActivePoint(val) {
      this.$emit("setActivePointSights", val);
    },
  },
  methods: {
    getDistanceSights() {
      const sights = this.distanceSights?.sights || [];
      if (sights.length) {
        const listSights = [];
        const sightsAirport = sights.filter(
          (item) => item.typeTitle === "Аэропорты"
        );
        const sightsStation = sights.filter(
          (item) => item.typeTitle === "Ж/д вокзалы РЖД"
        );

        if (sightsAirport.length) {
          listSights.push({
            title: this.t("sightsAirport"),
            list: sightsAirport,
            activePoint: undefined,
          });
        }
        if (sightsStation.length) {
          listSights.push({
            title: this.t("sightsStation"),
            list: sightsStation,
            activePoint: undefined,
          });
        }

        this.sights = listSights;
      }
    },
    setRoute(val) {
      this.$emit("setRoute", val);
    },
    setActivePoint(val, activeSights) {
      this.sights.forEach((item, index) => {
        if (activeSights === index) {
          item["activePoint"] = val;
        } else {
          item["activePoint"] = undefined;
        }
      });
    },
    clearActivePoint() {
      this.sights.forEach((item) => {
        item["activePoint"] = undefined;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map-sights {
  display: flex;
  flex-wrap: wrap;
  .sc-modal-body & {
    padding: 10px 0 0;
  }
  &--col {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: calc(50% - 10px);
    overflow: hidden;
    padding: 4px 0;
    transition: all 0.3s ease;
    width: calc(50% - 10px);
    font-size: 14px;
    margin-bottom: 10px;
    &:nth-child(odd) {
      margin-right: 10px;
    }
    &:nth-child(even) {
      margin-left: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .mobile & {
      width: 100%;
      max-width: 100%;
      &:nth-child(odd) {
        margin-right: 0;
      }
      &:nth-child(even) {
        margin-left: 0;
      }
    }
    .modal-wrap-list & {
      font-size: 12px;
      margin-bottom: 4px;
    }
  }
  &--title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    margin-bottom: 10px;
    .modal-wrap-list & {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
}
.map-sights-padding {
  height: 14px;
  transition: height 0.3s ease;
  &.active {
    height: 0;
  }
}
</style>
