<i18n lang="json">
{
    "ru": {
        "title": "Еще чуть-чуть…",
        "note": "Проверяем актуальные цены и доступность"
    },
    "en": {
        "title": "A little bit more…",
        "note": "We check the current prices and availability"
    }
}
</i18n>
<template>
  <div class="loading-price">
    <div class="loading-price--block">
      <div class="loading-price--loading-ico">
        <img src="~/assets/img/loader-bronevik-pink.gif" alt="" />
      </div>
      <div class="loading-price--title">
        {{ t("title") }}
      </div>
      <div class="tmp-font--medium">
        {{ t("note") }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.loading-price {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 0 18px;
  &--block {
    border-radius: 3px;
    box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.29);
    background-color: #ffffff;
    width: 100%;
    max-width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 26px 10px 24px;
  }
  &--loading-ico {
    height: 12px;
    position: relative;
    margin-bottom: 16px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 48px;
    }
  }
  &--title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
  }
}
</style>
