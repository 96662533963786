<i18n lang="json">
{
	"ru": {
    "wolkTime": "менее минуты пешком | {n} минута пешком | {n} минуты пешком | {n} минут пешком",
    "meter": "м",
    "distance_m": "{n} м",
    "distance_km": "{n} км",
    "route": "маршрут",
    "foot": "пешком",
    "on": "на",
    "byCar": "авто"
	},
	"en": {
    "wolkTime": "less than a minute on foot | {n} minute on foot | {n} minutes on foot | {n} minutes on foot",
    "meter": "m",
    "distance_m": "{n} m",
    "distance_km": "{n} km",
    "route": "route",
    "foot": "on foot",
    "on": "on",
    "byCar": "auto"
	}
}
</i18n>
<template>
  <div class="ropeway">
    <Carousel
      ref="slider"
      class="ropeway__slider"
      :settings="{
        itemsToShow: 1,
        transition: 300,
        infiniteScroll: false,
        wheelControl: false,
      }"
    >
      <template v-for="(item, i) in ropewayList" :key="i">
        <Slide class="ropeway__slide">
          <div class="ropeway__item" v-for="rw in item" :key="rw.id">
            <span class="icon-elevator"></span>
            <p class="ropeway__text">
              {{ rw.items[0].title }} ~
              <span class="distance">{{ rw.value }} </span>
              <span>{{ t("route") }} </span>
              <a @click="setRoute(rw.items[0], 'pedestrian')">{{ t("foot") }}</a>,
              <span>
                {{ t("on") }}
                <a @click="setRoute(rw.items[0], 'auto')">{{ t("byCar") }}</a>
              </span>
            </p>
          </div>
        </Slide>
      </template>
    </Carousel>
  </div>
</template>

<script>
// TODO: hooper - обновить настройки и стили
import { Carousel, Slide } from "vue3-carousel";
import { mapGetters, mapActions } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "Ropeway",
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
    ropeway() {
      return JSON.parse(JSON.stringify(this.getObjectData.location.relations));
    },
    ropewayList() {
      let counter = 0;
      const ropeway = [];
      for (const item of this.ropeway) {
        if (item.value < 100) {
          item.value = this.t('distance_m', { n: Math.round(item.value / 10) * 10});
        } else if (item.value < 950) {
          const a = String(item.value).slice(0, 1);
          const b = String(item.value).slice(1);
          item.value = this.t('distance_m', { n: Math.round(b / 100) === 1 ? `${a}50` : `${a}00`});
        } else {
          item.value = this.t('distance_km', { n: Math.round(item.value / 100) / 10}).replace('.', ',');
        }
        ropeway.push(item);
      }
      return ropeway
        .sort((a, b) => {
          return a.items[0].title
            .toString()
            .localeCompare(b.items[0].title.toString());
        })
        .reduce((acc, item) => {
          if (acc[counter]) {
            acc[counter].push(item);
            counter += 1;
          } else {
            acc[counter] = [item];
          }
          return acc;
        }, {});
    }
  },
  methods: {
    setRoute(to, routingMode) {
        let from = [];
        if (this.getObjectData.location.lat && this.getObjectData.location.lng) {
            from = [
                this.getObjectData.location.lat,
                this.getObjectData.location.lng,
            ];
        }

        let pointB = "";
        if (!to.lat || !to.lng) {
            pointB = `${this.locationObject.regionTitle}, ${this.getObjectData.location.location}, Горнолыжный подъёмник ${to.title}`;
        } else {
            pointB = [to.lat, to.lng];
        }

        this.$emit("route", {
            from: from,
            to: pointB,
            routingMode: routingMode,
        });
      this.$emit("setActivePointRopeway", to);
    }
  }
};
</script>

<style lang="scss" scoped>
:deep() {
  .hooper {
    height: inherit;
    width: calc(100% - 10px);
    outline: none;
    &-track {
      outline: none;
    }
    &-list {
      overflow: unset;
    }
  }
}
.ropeway {
  overflow: hidden;
  &__slide {
    padding-right: 10px;
  }
  &__text {
    color: #000;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin: 0;
    }
    .distance {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
    }
    .icon-elevator {
      display: inline-block;
      margin-right: 10px;
      width: 14px;
      min-width: 14px;
      height: 14px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuOTk2OTUgMTAuNzcxNEM0Ljk5Njk1IDExLjU4NzkgNS42NTg4NyAxMi4yNDk4IDYuNDc1MzUgMTIuMjQ5OEg5LjY0NTkzQzEwLjQ2MjQgMTIuMjQ5OCAxMS4xMjQzIDExLjU4NzkgMTEuMTI0MyAxMC43NzE0VjEwLjA2MjVINC45OTY5NUw0Ljk5Njk1IDEwLjc3MTRaIiBmaWxsPSIjNDE3NTA1Ii8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNCAwVjAuOTY2MTRMOC40OTgxNCAzLjU0NTE1VjYuMTI1SDkuODEwNjRDMTEuNDk5MyA2LjEyNSAxMi44NzMxIDcuNDk5MDMgMTIuODczMSA5LjE4NzVWMTAuOTM3NUMxMi44NzMxIDEyLjYyNiAxMS40OTkzIDE0IDkuODEwNjQgMTRINi4zMTA2NEM0LjYyMTk2IDE0IDMuMjQ4MTQgMTIuNjI2IDMuMjQ4MTQgMTAuOTM3NVY5LjE4NzVDMy4yNDgxNCA3LjQ5OTAzIDQuNjIxOTYgNi4xMjUgNi4zMTA2NCA2LjEyNUg3LjYyMzE0VjMuOTU1MjhMMCA3LjUyODY0VjYuNTYyNUwxNCAwWk0xMS45OTgxIDEwLjkzNzVWOS4xODc1QzExLjk5ODEgNy45ODE0IDExLjAxNjcgNyA5LjgxMDY0IDdINi4zMTA2NEM1LjEwNDU0IDcgNC4xMjMxNCA3Ljk4MTQgNC4xMjMxNCA5LjE4NzVWMTAuOTM3NUM0LjEyMzE0IDEyLjE0MzYgNS4xMDQ1NCAxMy4xMjUgNi4zMTA2NCAxMy4xMjVIOS44MTA2NEMxMS4wMTY3IDEzLjEyNSAxMS45OTgxIDEyLjE0MzYgMTEuOTk4MSAxMC45Mzc1WiIgZmlsbD0iIzQxNzUwNSIvPjwvc3ZnPg==);
      background-position: center;
    }

    a {
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #2d6cb4;
    }
  }
}
</style>
