<template>
  <div
    class="sorter"
    :class="{ 'sorter--active': showList }"
  >
    <p class="sorter__title">{{ title }}</p>
    <div>
      <div
        class="sorter__btn"
        @click="showList = !showList"
        v-click-outside="closeList"
      > {{ btnName }}
      </div>
      <transition name="fade">
        <ul
            class="sorter__options-list"
            v-if="showList"
        >
          <li
              v-for="option in sortOptions"
              :key="option.value"
              class="sorter__options-item"
              @click="sort(option.value)"
          > {{ option.title }}
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentsSorter',
  props: {
    sortOptions: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: 'Сортировка'
    }
  },
  data() {
    return {
      sortState: 'default',
      showList: false
    }
  },
  mounted () {
    const sortStateFromQuery = this.$route.query.commentsSortBy
    if(sortStateFromQuery) {
      this.sortState = sortStateFromQuery
      this.$emit('sort', sortStateFromQuery)
    }
  },
  computed: {
    btnName() {
      const map = {}
      this.sortOptions.forEach(option => {
        Object.defineProperty(map, option.value, {
          value: option.title,
          writable: true,
          enumerable: true,
          configurable: true
        })
      })
      return map[this.sortState]
    }
  },
  methods: {
    sort(val) {
      this.showList = false
      this.sortState = val
      this.$emit('sort', val)
      navigateTo({ query: { ...this.$route.query, commentsSortBy: val }});
    },
    closeList() {
      this.showList = false
    }
  }
};
</script>

<style lang="scss" scoped>
.sorter {
  position: relative;
  max-width: 350px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &__title {
    margin-right: 10px;
  }
  &__btn {
    padding: 7px 15px 8px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    &::after {
      content: "";
      display: inline-block;
      width: 15px;
      height: 7px;
      margin-left: 12px;
      background-image: url(~/assets/img/form/arrow.svg);
      background-position: 100% 50%;
      background-repeat: no-repeat;
      transition: transform 0.4s;
    }
    .sorter--active &::after {
      transform: rotate(180deg);
    }
  }
  &__options-list {
    display: block;
    margin-top: 8px;
    padding: 10px 0;
    border: 1px solid #d8d8d8;
    background: #fff;
    font-size: 16px;
    overflow-x: auto;
    z-index: 10;
    position: absolute;
    top: 100%;
    width: 260px;
    transition: all 0.5s ease-in-out;
  }
  &__options-item {
    padding: 0 14px;
    line-height: 1.9;
    font-size: 14px;
    cursor: pointer;
    &:hover,
    &:active,
    &--active {
      background: #f0f0f0;
    }
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #d9d9d9;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #777;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}

@media (max-width: 1024px) {
  .sorter {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 0;
    & > div {
      width: 100%;
    }
    &__title {
      display: none;
    }
    &__btn {
      width: 100%;
    }
    &__options-list {
      width: 100%;
    }
  }
}
</style>