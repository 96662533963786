<template>
  <div class="rating-box">
    <div class="icon-app-star2"></div>
    <span class="rating-count">{{ formatRating }}</span>
  </div>
</template>

<script>

export default {
  name: 'CommentRating',

  props: {
    rating: {
      type: Number,
      required: true
    }
  },

  computed: {
    formatRating() {
      return ((this.rating * 2).toFixed(1)).replace('.', ',')
    }
  }
};
</script>

<style scoped>
.icon-app-star2 {
  color: #f51449;
  font-size: 13px;
}
.rating-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.rating-count {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  color: black;
  margin-left: 3px;
}
</style>