<i18n lang="json">
  {
  "ru": {
  "so_discount_today":"со скидкой сегодня",
  "cntReviews": "0|{n} отзыв|{n} отзыва|{n} отзывов",
  "rating": "о проживании со средней оценкой {n} из 5",
  "noRating": "Пока еще нет отзывов"
  },
  "en": {
  "so_discount_today":"discount today",
  "cntReviews": "0|{n} review |{n} reviews |{n} reviews",
  "rating": "about a stay with an average rating of {n} out of 5",
  "noRating": "No reviews yet"
  }
  }
</i18n>
<template>
  <router-link
    :to="{
      name: 'Detail',
      params: { id: data.id },
      query: routerQuery,
    }"
    v-slot="{ href }"
    custom
  >
    <a
      :href="href"
      target="_blank"
      class="card-prices"
      @mousedown="clickObjectBronevik($event, linkBronevik)"
    >
      <div v-if="isBronevik" class="loading-price-bronevik">
        <img src="~/assets/img/loader-bronevik-gray.gif" alt="" />
      </div>
      <template v-else>
        <BasePriceBronevik
          v-if="priceBronevik"
          :priceBronevik="priceBronevik"
        />
        <PriceOrder v-else class="card-prices__price" :data="data" />
      </template>
      <Rating
        class="card-prices__rating"
        :data-rating="dataRating"
        :count-reviews="countReviews"
        :view-count="true"
        :is-small="true"
        viewPort="mobile"
        :reviewsText="true"
        :is-external-rating="isExternalRating"
      />
    </a>
  </router-link>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { documentBody } from "@sutochno/utils";

import PriceOrder from "./PriceOrder";
import BaseButtonOrder from "@/components/base/BaseButtonOrder.vue";
import Rating from "@/components/base/BaseRatingSearch";
import BasePriceBronevik from "@/components/base/BasePriceBronevik";
import { useI18n } from 'vue-i18n';

export default {
  name: "ObjectBlockPrices",
  components: {
    PriceOrder,
    BaseButtonOrder,
    Rating,
    BasePriceBronevik,
  },
  props: {
    data: {
      type: Object,
    },
    showDetail: {
      required: true,
    },
    linkBronevik: {
      type: String,
      default: ''
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      reviews: 0,
      ratio: 0,
    };
  },
  computed: {
    ...mapState("search", ["guests", "loadBronevikPrice", "objectsBronevik", "pricesBronevik"]),
    ...mapGetters("search", ["occupied", "getQuery", "maxGuests"]),
    discount() {
      if (this.data.price != this.data.price_without_hot) {
        const discount = 1 - this.data.price / this.data.price_without_hot;
        if (discount) return discount;
      }
      return 0;
    },
    priceBronevik() {
      let idBronevik = this.objectsBronevik.find(item => item == this.data.id)
      if (idBronevik) {
        return (this.pricesBronevik && this.pricesBronevik[idBronevik] && this.pricesBronevik[idBronevik]['price']) ? this.pricesBronevik[idBronevik]['price'] : 0
      } else {
        return 0
      }
    },
    isBronevik() {
      if (this.objectsBronevik.findIndex(item => item == this.data.id) == -1 || this.linkBronevik) {
        return false
      } else {
        if (this.loadBronevikPrice || !this.occupied) {
          return true
        } else {
          return false
        }
      }
    },
    maxRatingExternal() {
      const externalReviews = this.data.external_reviews
      if (externalReviews) {
        const ratingAirbnb = externalReviews[0]["rating"] ? externalReviews[0]["rating"] : 0
        const ratingBooking = externalReviews[1]["rating"] ? externalReviews[1]["rating"] : 0

        return Math.max(ratingAirbnb, ratingBooking)
      } else {
        return 0
      }
    },
    countReviewsExternal() {
      const externalReviews = this.data.external_reviews
      if (externalReviews) {
        const countAirbnb = externalReviews[0]["count"] ? externalReviews[0]["count"] : 0
        const countBooking = externalReviews[1]["count"] ? externalReviews[1]["count"] : 0

        return countAirbnb + countBooking
      } else {
        return 0
      }
    },
    isExternalRating() {
      if (this.data.count_reviews <= 1 && this.maxRatingExternal > 0) {
        return true
      } else {
        return false
      }
    },
    dataRating() {
      if (this.isExternalRating) {
        return this.maxRatingExternal
      } else {
        if (this.data.count_reviews > 1) {
          return this.data.rating
        } else {
          return 0
        }
      }
    },
    countReviews() {
      return this.data.count_reviews + this.countReviewsExternal
    },
    routerQuery() {
      const query = {
        occupied: this.occupied,
        guests_adults: this.guests.adults,
        guests_childrens: this.guests.childrens.join(','),
        id: this.getQuery.query.id,
        type: this.getQuery.query.type,
        term: this.getQuery.query.term,
      };
      if (this.data.hot_sale && this.data.hot_sale.sale) {
        return { ...query, burning_offer: 1 };
      }
      return query;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.reviews = this.t("cntReviews", this.data.count_reviews);
      this.ratio = this.t("rating", { n: this.data.rating });
    });
  },
  methods: {
    linkTo() {
      this.$parent.showDetail = !this.showDetail;
      documentBody.overflow("hidden");
    },
    clickObjectBronevik(event, link) {
      if (link || (link && event.which == 1) || (link && event.which == 2)) {
        event.preventDefault();
        window.open(link)
        sendEventGtag("search_sa", {
          click: "object_b_link",
          value: this.data.location.city.title,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-prices {
  padding: 0 15px 15px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  align-items: flex-start;

  :deep() {
    .price-order {
      grid-area: price;
    }
    .link {
      grid-area: link;
    }
  }

  &__rating {
    padding-top: 3px;
    display: flex;
    justify-content: flex-end;
  }

  &__price {
    :deep(.discount-price) {
      text-decoration: line-through;
      font-size: 18px;
      line-height: 23px;
    }
  }

  .discount {
    display: flex;
    align-items: center;
    text-align: center;
    grid-area: discount;
    color: #e10600;
    font-size: 13px;
    margin-bottom: 20px;
    .icon-app-fire {
      margin-right: 4px;
      font-size: 17px;
    }
  }

  .loading-price-bronevik {
    height: 12px;
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 48px;
    }
  }
}
</style>
