<i18n lang="json">
{
	"ru": {
    "reviews": "отзывов | {n} отзыв | {n} отзыва | {n} отзывов"
	},
	"en": {
    "reviews": "reviews | {n} review | {n} reviews | {n} reviews"
	}
}
</i18n>

<template>
  <div
    class="rating-list"
    :class="{ scale }"
    :data-rating="dataFilteredRating"
    itemprop="ratingValue"
    :content="filteredRating"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
    :style="`color: ${color}`"
  >
    <template v-if="countReviews === 1">
      <span
        class="rating-list__count"
        itemprop="reviewCount"
        >{{ t("reviews", countReviews) }}</span
      >
    </template>
    <template v-else-if="countReviews > 1">
      <span class="rating-list__rating" v-if="filteredRating">{{
        filteredRating
      }}</span>
      <span
        v-if="viewPort === 'desktop'"
        :class="{ 'count-white': countWhite }"
        class="rating-list__count"
        itemprop="reviewCount"
        >{{
          reviewsText
            ? t("reviews", countReviews)
            : t("reviews", countReviews)
        }}</span
      >
      <span
        v-if="viewPort === 'mobile'"
        :class="{ 'count-white': countWhite }"
        class="rating-list__count"
        itemprop="reviewCount"
        >{{ t("reviews", countReviews) }}</span
      >
    </template>
    <template v-if="countReviews === 0">
      <template v-if="filteredRating">
        <span class="rating-list__rating" v-if="isExternalRating">{{
          filteredRating
        }}</span>
        <span class="rating-list__rating" v-else>{{
          $filters.newRating(dataRating)
        }}</span>
      </template>
    </template>
    <slot />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
export default {
  name: "BaseRatingSearch",
  props: {
    dataRating: {
      type: Number,
      required: true,
      default: 0,
    },
    countReviews: {
      type: Number,
      required: false,
    },
    isHotel: {
      type: Object,
      required: false,
    },
    countWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    scale: {
      type: Boolean,
      default: false,
    },
    reviewsText: {
      type: Boolean,
      default: false,
    },
    viewPort: {
      type: String,
      default: "desktop",
    },
    color: {
      type: String,
      default: "#ffa900",
    },
    isExternalRating: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    filteredRating() {
      if (!this.dataRating) {
        return null;
      }
      return this.$filters.newRatingExternal(this.dataRating);
    },
    dataFilteredRating() {
      if (!this.filteredRating) {
        return 0;
      }
      return Math.floor(parseInt(this.filteredRating));
    }
  }
};
</script>

<style lang="scss" scoped>
$colors: (
  1: #D12D1E,
  2: #D8491F,
  3: #DE641F,
  4: #E37F1F,
  5: #ECA71E,
  6: #D9A729,
  7: #AFA53E,
  8: #86A452,
  9: #5DA267,
  10: #32A07C
);

.rating-list {
  display: flex;
  align-items: center;
  white-space: nowrap;

  @each $rating, $color in $colors {
    &[data-rating="#{$rating}"] .rating-list__rating {
      background-color: $color;
    }
  }

  &__rating {
    margin-right: 6px;
    padding-left: 6px;
    padding-right: 6px;
    min-width: 30px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #fff;
  }

  &__count {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #1C1C1C;
  }
}
</style>
