<i18n lang="json">
{
    "ru": {
        "warningBronevikText1": "Забронировано на ваши даты!",
        "warningBronevikText2": "Жилье пользуется спросом. ",
        "occupiedNo1": "Выберите даты проживания",
        "occupiedNo2": ", чтобы уточнить доступность и стоимость."
    },
    "en": {
        "warningBronevikText1": "Booked for your dates!",
        "warningBronevikText2": "Housing is in demand.",
        "occupiedNo1": "Select dates of stay",
        "occupiedNo2": ", to clarify availability and cost."
    }
}
</i18n>
<template>
  <div class="warning-broneevik" :class="{ 'no-date': !occupied }">
    <div
      class="warning-broneevik-side"
      v-if="!occupied"
      @mousedown.stop.prevent="showCalendarBronevik"
      @click.stop.prevent="showCalendarBronevik"
      @touchend.stop.prevent="showCalendarBronevik"
    />
    <div class="icon-app-clock" v-if="occupied"></div>
    <div class="icon-app-calendar-5" v-else></div>
    <div class="warning-broneevik--text">
      <template v-if="occupied">
        {{ t("warningBronevikText1") }} <br />
        <span>{{ t("warningBronevikText2") }}</span>
      </template>
      <template v-else>
        {{ t("occupiedNo1") }}<span>{{ t("occupiedNo2") }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

export default {
  name: "BaseWarningBronevik",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("search", ["occupied"]),
  },
  methods: {
    showCalendarBronevik: _.debounce(function () {
      this.$emit("showCalendarBronevik");
    }, 100),
  },
};
</script>

<style lang="scss" scoped>
.warning-broneevik {
  background: #fcd2d3;
  border-radius: 5px;
  color: #f51449;
  font-size: 12px;
  display: flex;
  padding: 8px 10px;
  line-height: 1.2;
  position: relative;
  z-index: 3;

  &-side {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .icon-app-clock {
    font-size: 16px;
    margin-right: 10px;
    padding-top: 1px;
  }
  .icon-app-calendar-5 {
    font-size: 16px;
    margin-right: 10px;
    padding-top: 1px;
  }
  &--text {
  }
  &.no-date {
    background: #f6f6f6;
    color: #000000;
  }
}
</style>
