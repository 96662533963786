<i18n lang="json">
{
	"ru": {
		"searchMove": "Поиск при перемещении карты",
		"searchMoveMobile": "Поиск при перемещении",
    "searchCurrentArea": "Обновить поиск",
    "searchCurrentAreaMobile": "Обновить",
    "hint": "Нажмите, чтобы увидеть предложения на этом участке карты"
	},
  "en": {
    "searchMove": "search while moving the map",
    "searchMoveMobile": "Search while moving",
    "searchCurrentArea": "Update search",
    "searchCurrentAreaMobile": "Update",
    "hint": "Click to see offers on this section of the map"
  }
}
</i18n>

<template>
  <MapLoader v-if="isLoading" class="map-loader"/>
  <div
    v-else-if="showBlockUpdate"
    class="block-update"
    @click="onClick"
  >
    <div class="block-update__wrap">
      <template v-if="!isCheck && isMoved">
        <span class="icon-app-refresh"></span>
        <span class="text-nowrap">
          {{ nameSearchCurrentArea }}
        </span>
      </template>
      <template v-else>
        <b-form-checkbox v-model="isCheck" :checked="isCheck" :is-checked-black="true">
          {{ nameSearchMove }}
        </b-form-checkbox>
      </template>
    </div>
    <template v-if="!isCheck && isMovedData && isShowHintBlockUpdate">
      <div class="block-update--hint">
        {{ t("hint") }}
        <span class="icon-app-cross" @click.stop.self="onCloseHint"></span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import MapLoader from "@/components/base/Loader/MapLoader.vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "BlockUpdate",
  props: {
    isMoved: {
      default: false,
      type: Boolean,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    showBlockUpdate: Boolean
  },
  components: {
    MapLoader,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isCheck: false,
      isShowHintBlockUpdate: !this.$cookies.get("DontShowHintBlockUpdate"),
      isMovedData: false
    };
  },
  computed: {
    ...mapGetters("user", ["isMobile"]),
    nameSearchMove() {
      return this.isMobile ? this.t("searchMoveMobile") : this.t("searchMove");
    },
    nameSearchCurrentArea() {
      return this.isMobile ? this.t("searchCurrentAreaMobile") : this.t("searchCurrentArea");
    },
  },
  watch: {
    modelValue: function (value) {
      this.isCheck = value;
      sessionStorage.setItem("isCheckBlockUpdate", value);
    },
    isCheck() {
      if (this.isCheck) {
        this.closeHint();
      }
      this.$emit("update:modelValue", this.isCheck);
    }
  },
  beforeMount () {
    this.isMovedData = this.isMoved;
  },
  mounted() {
    this.isCheck = sessionStorage.getItem("isCheckBlockUpdate") === "true" ? true : this.modelValue;
  },
  beforeUpdate () {
    if (this.$parent.$parent.isMoved === false || this.$parent.$parent.isMoved === true) {
      this.isMovedData = this.$parent.$parent.isMoved;
    }
  },
  methods: {
    onClick() {
      if (!this.isCheck && !this.isLoading && this.isMovedData) {
        this.$emit("update");
        this.closeHint();
      }
      if(this.isMovedData) {
        this.isCheck = true;
      }
    },
    onCloseHint() {
      sendEventGtag("search_sa", {
        click: "map_info_close",
      });
      this.closeHint();
    },
    closeHint() {
      this.$cookies.set("DontShowHintBlockUpdate", true, "1d");
      this.isShowHintBlockUpdate = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.block-update {
  width: 100%;
  &__wrap {
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 17px;
    background: #fff;
    color: #000;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 0 15px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    border-radius: 19px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.17);

    &:hover {
      @media only screen and (min-width: 768px) {
        background-color: #f6f6f6;
      }
    }
  }
  .sc-checkbox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: -4px -8px;
    padding: 8px 8px;
    cursor: pointer;
    white-space: nowrap;
    color: #000;
  }
  .icon-app-refresh {
    font-size: 16px;
    margin-right: 8px;
  }
  .text-nowrap {
    &.section {
      padding: 4px 0;
    }
  }
  &--lock::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff75;
    z-index: 1;
  }

  &--lock::after {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url("~/assets/img/loading.svg");
    background-size: contain;

    animation: loading 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes loading {
      from {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  }
  &--hint {
    position: absolute;
    top: 60px;
    display: flex;
    align-items: center;
    width: 96%;
    max-width: max-content;
    transform: translate(-50%, 10px);
    border-radius: 3px;
    color: white;
    background-color: #444444;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    left: 50%;
    @media only screen and (max-width: 992px) {
      font-size: 12px;
    }
    &::before {
      display: block;
      content: "";
      backface-visibility: hidden;
      transform: translateZ(0) scale(1, 1) translate(-50%, -100%);
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #444 transparent;
    }
    .icon-app-cross {
      margin-left: 20px;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .sc-checkbox {
    :deep(input:active~.sc-checkbox--mark, input:checked~.sc-checkbox--mark) {
      background-image: url("~/assets/img/checkbox-white.svg");
    }
  }
  :deep(.sc-checkbox--mark) {
    margin-left: 20px;
    margin-right: 0;
    background-color: #fff;
  }
}
.map-loader {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
</style>
