<i18n lang="json">
{
  "en": {
    "clearFilters": "Clear filter",
    "noVariants": "No variants",
    "noVariantsDescription": "No listing meeting your conditions was found.",
    "open": "Open the map",
    "close": "Show a list of objects"

  },
  "ru": {
    "clearFilters": "Показать доступные варианты",
    "noVariants": "В этой области нет вариантов",
    "noVariantsDescription": "Сдвиньте карту или сбросьте фильтры, чтобы увидеть предложения.",
    "open": "Раскрыть карту",
    "close": "Показать список объектов",
    "searchCurrentArea": "искать здесь"
  }
}
</i18n>

<template>
  <div
    class="map-wrap"
    v-observe-visibility="{
      callback: (isVisible, entry) => visibilityChanged(isVisible, entry),
      throttle: 0,
      intersection: {
        threshold: 0.00001,
      },
    }"
  >
    <YandexMap
      ref="mapComponent"
      :class="{
        'map-mobile': viewPort === 'mobile',
        'map-desktop': viewPort === 'desktop',
      }"
      :coords="mapCenter"
      :zoom="mapZoom"
      :scroll-zoom="viewPort === 'desktop' ? false : true"
      :custom-events="customEventsMap"
      :controls="
        viewPort === 'desktop' ? ['trafficControl', 'typeSelector'] : []
      "
      :settings="{
        apiKey,
      }"
      :options="{
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true,
        minZoom: 5,
      }"
      @ready="onInitMap"
      @update:customEvents="updateCustomEvents"
    >
      <template v-if="isResults">
        <CardMap
          v-if="activeMapCard && viewPort === 'desktop'"
          :is-map="true"
          :object-data="activeMapCard"
          :style="{
            opacity: `${coordinate.isVisible ? 1 : 0}`,
            top: `${coordinate.top}px`,
            left: `${coordinate.left}px`,
          }"
          @onRender="onRender"
        />
        <Placemark
          :placemarks="placemarks"
          @click="onClick"
          @mouseenter="onEnter"
        />
      </template>
      <template
        v-if="
          loadHomeMarker &&
          getObjectData.location.lat &&
          getObjectData.location.lng
        "
      >
        <Placemark
          key="home"
          :placemarks="{
            imageFlag: {
              coords: [getObjectData.location.lat, getObjectData.location.lng],
              typeMark: 'html',
              options: {
                iconLayout: `<div id='${getObjectData.id}' class='map-placemark-home'/>`,
                iconImageSize: [-35, -25],
                iconImageOffset: [35, 0],
                zIndex: 700,
              },
            },
          }"
          @click="clickHome"
        />
      </template>
      <template v-if="routeMapView">
        <Route
          :from="getRouteMap.from"
          :to="getRouteMap.to"
          :routingMode="getRouteMap.routingMode"
          @open="hideRoute"
        />
      </template>

      <ObjectListMap
        v-if="isResults && viewPort === 'mobile'"
        :results="results || []"
      />
      <template v-else>
        <template v-if="viewPort === 'mobile'">
          <div class="map-mobile__options" v-if="metroLength > 0">
            <Metro
              @route="setRoute"
              @setActivePointMetro="setActivePoint"
            />
            <MapSights
              @setRoute="setRoute"
              @setActivePointSights="setActivePointSights"
              :modal-map-view="modalMapView"
              :active-point-ropeway-metro="activePointRopewayMetro"
            />
          </div>
          <div
            class="map-mobile__options"
            v-else-if="getObjectData.location.seas?.length"
          >
            <Sea @route="setRoute" />
            <MapSights
              @setRoute="setRoute"
              @setActivePointSights="setActivePointSights"
              :modal-map-view="modalMapView"
              :active-point-ropeway-metro="activePointRopewayMetro"
            />
          </div>
          <div
            class="map-mobile__options"
            v-else-if="
              getObjectData.location.relations &&
              getObjectData.location.relations.length > 0
            "
          >
            <Ropeway
              @route="setRoute"
              @setActivePointRopeway="setActivePoint"
            />
            <MapSights
              @setRoute="setRoute"
              @setActivePointSights="setActivePointSights"
              :modal-map-view="modalMapView"
              :active-point-ropeway-metro="activePointRopewayMetro"
            />
          </div>
          <template v-else>
            <div v-if="isSights" class="map-mobile__options">
              <MapSights
                @setRoute="setRoute"
                @setActivePointSights="setActivePointSights"
                :modal-map-view="modalMapView"
                :active-point-ropeway-metro="activePointRopewayMetro"
              />
            </div>
          </template>
        </template>
      </template>
    </YandexMap>
    <HowToGetMapNew v-model="routeMap" />
    <div v-if="viewPort === 'desktop'">
      <MetroDesktop
        @route="setRoute"
        v-if="metroLength > 0"
        :isShowMetro="isShowMetro"
        :active-point-sights="activePointSights"
        @setActivePointMetro="setActivePoint"
      />
      <RopewayDesktop
        @route="setRoute"
        :ropeway="ropeway"
        :active-point-sights="activePointSights"
        @setActivePointRopeway="setActivePoint"
        v-if="
          getObjectData.location.relations &&
          getObjectData.location.relations.length > 0
        "
      />
      <MapSights
        @setRoute="setRoute"
        @setActivePointSights="setActivePointSights"
        :modal-map-view="modalMapView"
        :active-point-ropeway-metro="activePointRopewayMetro"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import _ from "lodash";

import { event as sendEventGtag } from "@/composables/gtag/index.js";
import useStoreWatchersDetailMap from "@/composables/detail/map/useStoreWatchersDetailMap";

import { YandexMap, Placemark, Route } from "@sutochno/YandexMap";

import BlockUpdate from "@/components/desktop/NewSearch/BlockUpdate.vue";
import ObjectListMap from "./ObjectListMap";
import CardMap from "@/components/desktop/Detail/CardMap";

import Ropeway from "./Ropeway";
import RopewayDesktop from "./RopewayDesktop";
import Metro from "./Metro";
import MetroDesktop from "./MetroDesktop";
import Sea from "./Sea";

import Supercluster from "supercluster";

import HowToGetMapNew from "@/components/mobile/Detail/Map/HowToGetMapNew.vue";
import MapSights from "./MapSights.vue";

export default {
  name: "MapLayout",
  components: {
    BlockUpdate,
    YandexMap,
    Placemark,
    Route,
    Ropeway,
    RopewayDesktop,
    Metro,
    MetroDesktop,
    Sea,
    ObjectListMap,
    CardMap,
    HowToGetMapNew,
    MapSights,
  },
  props: {
    results: {
      require: true,
      type: Array,
    },
    status: {
      type: String,
      require: true,
    },
    isResults: {
      type: Boolean,
      default: false,
    },
    routeInit: {
      type: Object,
    },
    ropeway: {
      type: Array,
      required: false,
    },
    isShowMetro: {
      type: Boolean,
      required: false,
    },
    modalMapView: {
      type: Boolean,
    },
  },
  setup() {
    //TODO: nuxt проверить актуален ли вотчер
    useStoreWatchersDetailMap();
  },
  data() {
    return {
      routeMap: null,
      mapZoom: 13,
      mapCenter: [55.745161166568124, 37.62050512070092],
      // prod key 590046a8-de02-4e92-bef6-dcb4d64df9c5
      apiKey:
        process.env.NODE_ENV === "development"
          ? "ed03bb3a-3f94-48b0-a552-7a2b6d165870"
          : this.$route.name === "Detail" ? "991db111-5570-4d7b-975b-e39c3c95bf34"
          : "bdf0aff7-5600-485a-88c7-7c764a34c9f3",
      // this.$localStorage &&
      // this.$localStorage.get("adminInfo", {}, Object).isSearchGeocoderMap
      //   ? "590046a8-de02-4e92-bef6-dcb4d64df9c5"
      //   : "",
      cancelSource: null,
      hoverPlacemark: null,
      recentlyResults: [], // Выборка выдачи для карты
      historyMapActivity: {},
      lockUpdate: false, // Блокирвока между анимациями
      isMoved: false, // Сдвинул карту?
      isNoBlockUpdate: true, // Блокировка обновления пользователем
      loadHomeMarker: false,
      coordinate: {
        top: 0,
        left: 0,
      },
      pageNum: 1,
      customEventsMap: ["actionbegin", "actionend", "mouseup", "touchstart"],
      activePointRopewayMetro: undefined,
      activePointSights: undefined,
    };
  },
  watch: {
    historyMapActivity(value) {
      localStorage.setItem("historyMapActivity", JSON.stringify(value));
    },
    selectedMapPointId: function (value, oldValue) {
      // Помечаем метку как посещеную
      if (oldValue) {
        this.historyMapActivity[oldValue] = "visited";
      }
      if (value) {
        this.historyMapActivity[value] = "active";
      }
    },
    bound: {
      deep: true,
      handler: function (bound) {
        // console.log("BOOOOOOM!!");
        if (!this.myMap) {
          // console.warn("Попытка перемещения карты до загрузки карты");
          return;
        }
        const { center, zoom } = ymaps.util.bounds.getCenterAndZoom(
          [
            [bound.SW.lat, bound.SW.lng],
            [bound.NE.lat, bound.NE.lng],
          ],
          this.myMap.container.getSize(),
          ymaps.projection.wgs84Mercator,
          {
            inscribe: false,
          }
        );

        if (zoom !== this.mapZoom) {
          this.mapZoom = zoom;
        }
        this.mapCenter = center;
      },
    },
    results: {
      handler: function (results) {
        if (results && this.viewPort === "mobile") {
          this.$store.commit("search/setSelectedMapPointId", results[0]?.id);
        }
      },
    },
  },
  computed: {
    ...mapGetters("detail", ["getObjectData", "getRouteMap"]),
    ...mapState("detail", ["routeMapView", "showPath", "distanceSights"]),
    ...mapState("search", [
      "filters",
      "totalFound",
      "location",
      "cardVisible",
      "selectedMapPointId",
      "bound",
      "switchMap",
    ]),
    ...mapState("user", ["currency", "viewPort"]),
    ...mapGetters("search", ["occupied"]),
    ...mapGetters("search", { maxGuests: "maxGuests" }),

    placemarks() {
      const points = JSON.parse(JSON.stringify(this.results || []));

      // SUPECLUSTER
      const geoPoints = points.map((p) => ({
        type: "Feature",
        id: p.id,
        geometry: {
          type: "Point",
          coordinates: [p.lng, p.lat],
        },
      }));
      const index = new Supercluster({
        // Примерно подабрал чтобы вблизи агресивнее брались метки в кластер
        // Есть кейсы где можно сказать метки на разные поъезды и в каждом по много объектов
        radius: (10 * 1) / (1.5 ^ this.mapZoom) + 10,
        minZoom: 8,
        maxZoom: 19,
      });
      index.load(geoPoints);
      // ЭТО ПРАВИЛЬНО, НО БУДЕТ БАГА ТОГДА ТК BOUNDS поменяться быстрее чем с апи придет
      // const cluster = index.getClusters([this.bound.SW.lng, this.bound.SW.lat, this.bound.NE.lng, this.bound.NE.lat], this.mapZoom);
      // БЕРЕМ ВСЮ КАРТУ ИБО НЕХРЕН
      const cluster = index.getClusters([-180, -85, 180, 85], this.mapZoom);

      //SMART CONST
      const offsetZoom = {
        19: 0.00009,
        18: 0.0002,
        17: 0.00025,
        16: 0.0003,
        15: 0.0008,
        14: 0.002,
        13: 0.0035,
        12: 0.005,
        11: 0.006,
        10: 0.008,
        9: 0.00975,
        8: 0.0125,
        7: 0.125,
        // Дальше смысла нет
      };

      const meanPoints = cluster.map((p) => {
        if (p.properties && p.properties.cluster) {
          const [cLng, cLat] = p.geometry.coordinates;
          const cPoints = index.getLeaves(p.properties.cluster_id, Infinity); // Вынимаем из кластера все точки
          const nums = cPoints.length;
          const r = offsetZoom[this.mapZoom] || 0.2; // Радиус измеряеться в градусах

          // Перестановка точек по окружности
          cPoints.forEach((cPoint, index) => {
            const path = ((2 * Math.PI) / nums) * index;
            const newLng = cLng + r * Math.cos(path);
            const newLat =
              cLat + Math.sin((cLat * Math.PI) / 180) * r * Math.sin(path);

            cPoint.geometry.coordinates = [newLng, newLat];
            // cPoint.angle = `${-((path/(2*Math.PI))*360  - 90)}deg`
          });
          return cPoints;
        }
        return p;
      });
      const fixPoints = _.flatten(meanPoints).reduce((acc, p) => {
        acc[p.id] = {
          lat: p.geometry.coordinates[1],
          lng: p.geometry.coordinates[0],
        }; //, angle: p.angle
        return acc;
      }, {});

      return points.reduce((acc, value) => {
        const { lat, lng } = fixPoints[value.id] || value; // FALLBACK так на всякий случай

        const classHover = this.hoverPlacemark == value.id ? "hover" : "";
        const classHistory = this.historyMapActivity[value.id] || "";
        const isActive = this.historyMapActivity[value.id] === "active";

        if (value.id != this.getObjectData.id) {
          acc[value.id] = {
            coords: [lat, lng],
            typeMark: "html",
            options: {
              // Если ховер то выше
              zIndex:
                this.hoverPlacemark == value.id ? 668 : isActive ? 667 : 666,
              iconLayout: `<div id="${
                value.id
              }" class="map-placemark ${classHover} ${classHistory}">
                ${
                  value.booking_now
                    ? `<span class="icon-app-lightning"></span>`
                    : ""
                }
                ${new Intl.NumberFormat(this.$i18n.locale, {
                  style: "currency",
                  currency: (this.currency && this.currency.title) || "RUB",
                  minimumFractionDigits: 0,
                }).format(value.price.average_per_night || value.price.per_night)}
                </div>`,
              iconShape: {
                type: "Rectangle",
                // Прямоугольник описывается в виде двух точек - верхней левой и нижней правой.
                coordinates: [
                  [-35, -25],
                  [35, 0],
                ],
              },
            },
          };
        }
        return acc;
      }, {});
    },
    activeMapCard() {
      if (this.selectedMapPointId && this.results.length && this.cardVisible) {
        return this.results.find(
          (v) => v.id === Number(this.selectedMapPointId)
        );
      } else {
        return null;
      }
    },
    metroLength() {
      return this.getObjectData?.metro?.length || 0;
    },
    isSights() {
      const sights = this.distanceSights?.sights || [];
      return sights.length;
    },
  },
  unmounted() {
    // window.removeEventListener("resize", this.handleWindowResize);
    this.loadHomeMarker = false;
  },
  created() {
    this.mapCenter = [
      (this.bound.SW.lat + this.bound.NE.lat) / 2,
      (this.bound.SW.lng + this.bound.NE.lng) / 2,
    ];
  },
  mounted() {
    this.historyMapActivity = Object.keys(
      JSON.parse(localStorage.getItem("historyMapActivity") || "{}")
    ).reduce((acc, key) => ({ ...acc, [key]: "visited" }), {});
  },
  methods: {
    ...mapMutations("search", ["updateSwitchMap"]),
    ...mapMutations("detail", ["routeMapViewMutate"]),
    ...mapActions("detail", ["setRouteMap", "getDistanceSights"]),
    // TODO: HACK LOCK UPDATE BEFORE CLICK CARD
    hideRoute() {
      // закоментирвал потому что этот метод вызывал баги
      /* this.routeMapViewMutate(false);
      this.mapCenter = [
        this.getObjectData.location.lat,
        this.getObjectData.location.lng
      ]; */
    },
    setRoute(e) {
      // перед тем как вызвать новый route необходимо закрыть все старые
      this.routeMapViewMutate(false);
      this.$nextTick(() => {
        this.setRouteMap(e);
        this.routeMapViewMutate(true);
      });
    },
    clickHome(mark) {
      if (this.viewPort === "mobile") {
        sendEventGtag("object_sa_m", {
          click: "map_big_object",
        });
      } else {
        this.$store.commit("search/setSelectedMapPointId", mark.id);
        this.$store.commit("search/setCardVisible", true);
        this.$store.commit("search/setCardVisible", false);
      }
    },
    onEnter(mark) {
      this.$store.commit("search/setHoverMapPointId", mark.id);
      this.$store.commit("search/setCardVisible", true);
    },
    onRender(card) {
      const placemark = document
        .getElementById(this.selectedMapPointId)
        .getBoundingClientRect();
      const map = document.getElementById("yandexMap").getBoundingClientRect();

      // Возможно надо учитывать ширину маркера, НО КТО ЕЕ ЗАМЕТИТ ;)
      const offsetTop = Math.min(
        placemark.top - (card.height + 76) - map.top,
        0
      );
      const offsetLeft = Math.min(
        placemark.left - (card.width / 2 - 7) - map.left,
        0
      );
      const offsetRight = Math.max(
        placemark.right + (card.width / 2 + 20) - map.right,
        0
      );

      let top = placemark.top - map.top - card.height - 10;
      let left =
        placemark.left - map.left - card.width / 2 + placemark.width / 2;
      // Если смещения нет то просто показываем в нужно
      if (offsetLeft == 0 && offsetRight == 0 && offsetTop == 0) {
        this.coordinate = {
          top,
          left,
          isVisible: true,
        };
        this.lockUpdate = false;
      } else {
        this.coordinate = {
          top,
          left,
          isVisible: false,
        };
        this.lockUpdate = true;

        // Отступ в пикселах от центра
        let projection = this.myMap.options.get("projection");
        let pixels = this.myMap.converter.globalToPage(
          projection.toGlobalPixels(
            // географические координаты
            this.myMap.getCenter(),
            this.myMap.getZoom()
          )
        );
        // Расчет разницы в координатах
        pixels[0] += offsetLeft + offsetRight; //x
        pixels[1] += offsetTop; //y

        let moveToCoords = projection.fromGlobalPixels(
          this.myMap.converter.pageToGlobal(pixels),
          this.myMap.getZoom()
        );

        this.myMap.panTo(moveToCoords, { duration: 400 }).then(async () => {
          this.coordinate = {
            top: top - offsetTop,
            left: left - (offsetLeft + offsetRight),
            isVisible: true,
          };
          await this.$nextTick();
          await this.$nextTick();
          await this.$nextTick();
          this.lockUpdate = false;
        });
      }
    },
    onActionEnd() {
      // console.log("onActionEnd");
      const bound = this.myMap.getBounds();
      // Узнаем центры
      const from = ymaps.util.bounds.getCenter([
        [this.bound.SW.lat, this.bound.SW.lng],
        [this.bound.NE.lat, this.bound.NE.lng],
      ]);
      const to = ymaps.util.bounds.getCenter(bound);
      // deltaDistance
      const deltaDistance = ymaps.coordSystem.geo.getDistance(from, to);

      const distanceZoomFrom = ymaps.coordSystem.geo.getDistance(
        [this.bound.SW.lat, this.bound.SW.lng],
        [this.bound.NE.lat, this.bound.NE.lng]
      );
      const distanceZoomTo = ymaps.coordSystem.geo.getDistance(
        bound[0],
        bound[1]
      );

      // Удаление слоя карты
      // if (this.myMap.getZoom() === 19) {
      //   // console.log("REMOVE markLayer");
      //   this.myMap &&
      //     this.markLayer &&
      //     this.myMap.layers.remove(this.markLayer);
      // } else {
      //   // console.log("ADD markLayer");
      //   this.myMap && this.markLayer && this.myMap.layers.add(this.markLayer);
      // }

      // Если сдвинули на < 10% экрана и зум не менялся блокируем обновления
      if (
        deltaDistance < distanceZoomFrom * 0.1 &&
        Math.abs(distanceZoomFrom - distanceZoomTo) < 200
      ) {
        // console.log("LOCK - NO DIFF");
        return;
      } else if (this.lockUpdate) {
        // Блокирвока при открытие карточки на карте
        // console.log("LOCK - openCard");
        return;
      } else if (!this.isNoBlockUpdate) {
        // Пользователь решил не обновлять автоматически
        // console.log("LOCK - USER BLOCK");
        this.isMoved = true;
        return;
      } else {
        // console.log("UPDATE");
        this.onActionEndDebounced();
      }
    },
    onActionEndDebounced: _.debounce(function () {
      // console.log("onActionEndDebounced");
      const bound = this.myMap.getBounds();
      this.$store.commit("search/setBound", {
        NE: {
          lat: bound[1][0],
          lng: bound[1][1],
        },
        SW: {
          lat: bound[0][0],
          lng: bound[0][1],
        },
      });
    }, 100),

    onInitMap(map) {
      this.$emit("ready");
      this.myMap = map;

      this.mapZoom = 16;
      this.mapCenter = [
        this.getObjectData.location.lat,
        this.getObjectData.location.lng,
      ];

      if (this.viewPort === "desktop") {
        this.myMap.controls.add("zoomControl", {
          size: "large",
          position: {
            right: 10,
            top: 100,
          },
        });
      }
      this.$nextTick(() => {
        // После изменения BBOX пойдет пойск
        const bound = this.myMap.getBounds();
        this.$store.commit("search/setBound", {
          NE: {
            lat: bound[1][0],
            lng: bound[1][1],
          },
          SW: {
            lat: bound[0][0],
            lng: bound[0][1],
          },
        });
        this.$nextTick(() => {
          // this.myMap.events.add("boundschange", (event) => {
          //   if (event.get("newZoom") > event.get("oldZoom")) {
          //     this.$ga.event("search_sa_m", "click", "map_plus");
          //   }
          //   if (event.get("newZoom") < event.get("oldZoom")) {
          //     this.$ga.event("search_sa_m", "click", "map_minus");
          //   }
          // });
          this.loadHomeMarker = true;
          this.isMoved = false;
        });
      });
    },
    onActionBegin() {
      if (this.selectedMapPointId && !this.lockUpdate) {
        this.$store.commit("search/setSelectedMapPointId", null);
      }
    },
    onForceUpdateArea() {
      setTimeout(() => {
        this.isMoved = false;
      }, 500);
      this.onActionEndDebounced();
      sendEventGtag("search_sa_m", {
        click: "map_search_area",
      });
    },
    onClick(mark) {
      this.$store.commit("search/setSelectedMapPointId", mark.id);
      sendEventGtag("search_sa_m", {
        click: "map_price",
      });
    },
    onMouseUp() {
      this.$store.commit("search/setSelectedMapPointId", null);
    },
    onTouch() {
      setTimeout(() => {
        this.$nextTick(() => {
          if (!this.selectedMapPointId && !this.switchMap) {
            this.$store.commit(
              "search/setSelectedMapPointId",
              this.results[0]?.id
            );
          }
          if (!this.switchMap) {
            sendEventGtag("search_sa_m", {
              click: "area_map_expand",
            });
          }
          this.updateSwitchMap(true);
        });
      }, 100);
    },
    // обработчик для customEventsMap
    updateCustomEvents(val) {
      if (!val?.name) return;
      switch (val.name) {
        case "actionbegin":
          this.onActionBegin(val.event);
          break;
        case "actionend":
          this.onActionEnd(val.event);
          break;
        case "mouseup":
          this.onMouseUp(val.event);
          break;
        case "touchstart":
          this.onTouch(val.event);
          break;
        default:
          break;
      }
    },
    visibilityChanged(isVisible) {
      if (isVisible && !this.distanceSights) {
        this.getDistanceSights(this.$route.params.id);
      }
    },
    setActivePoint(val) {
      this.activePointRopewayMetro = val;
    },
    setActivePointSights(val) {
      this.activePointSights = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-mobile,
.map-desktop {
  position: relative;
  height: 100%;
  height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100 - 112px);

  &__options {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    padding: 15px 20px;
  }

  &--force {
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 20px;
    border-radius: 3px;
    background: #ffffff;
    color: #444444;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 12px 10px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
    }
  }

  :deep() {
    .block-update--hint {
      width: 150%;
    }

    .map-placemark-home {
      position: relative;
      display: flex;
      top: -5px;
      width: 28px;
      height: 40px;
      transform: translate(-50%, -100%);
      background-image: url("~/assets/img/map/point-home.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }

    .map-placemark {
      position: relative;
      display: inline-block;
      white-space: nowrap;
      background-color: #ffffff;
      color: #000;
      height: 24px;
      box-sizing: border-box;
      padding: 0 8px;
      font-size: 12px;
      line-height: 24px;
      border-radius: 24px;
      transform: translate(-50%, -100%);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      top: -5px;
      &:after {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translate(-50%, 100%) rotate(45deg);
        content: "";
        background-color: #ffffff;
        width: 8px;
        height: 8px;
        // box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      }
      .icon-app-lightning {
        display: inline-block;
        font-size: 11px;
        position: relative;
        top: 1px;
        width: 9px;
        display: none;
      }
      &.active {
        background-color: #444444;
        color: white;
        &:after {
          background-color: #444444;
        }
        z-index: 999;
      }
      &.visited {
        background-color: #ffffff;
        color: #717171;
        &:after {
          background-color: #ffffff;
        }
      }
      &.hover:not(.active) {
        transform-origin: 50% 100%;
        transform: translate(-50%, -100%) scale(1.2, 1.2);
        z-index: 998;
      }
    }
  }
}
.map-desktop {
  height: 492px;
  max-height: 492px;
  margin-bottom: 10px;
}


  .map-wrap {
    position: relative;
  }

</style>
