<i18n lang="json">
{
  "ru": {
    "certTitle": "Свидетельство о присвоении категории",
    "until": "до"
  },
  "en": {
    "certTitle": "Certificate of category assignment",
    "until": "until"
  }
}
</i18n>

<template>
  <div class="cert__container">
    <span class="icon-app-cert cert__red-icon"></span>
    <div class="cert__content">
      <p class="cert__title">{{ t("certTitle") }}</p>
      <p class="cert__number">
        {{ certNumber }} - {{ t("until") }} {{ new Date(certDate).toLocaleDateString() }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  certNumber: {
    type: String,
    required: true,
  },
  certDate: {
    type: String,
    required: true,
  },
});
</script>

<style scoped lang="scss">
.cert {
  &__container {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    max-width: 308px;
    margin-bottom: 19px;
  }
  &__red-icon {
    color: #ee204d;
    font-size: 18px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  &__title {
    font-family: "Inter";
    font-weight: 600;
    font-size: 12px;
    color: #1c1c1c;
    white-space: nowrap;
  }
  &__number {
    font-family: "Inter";
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #727272;
    margin-top: 5px;
  }
}

@media (max-width: 425px) {
  .cert__container {
    max-width: 100%;
    margin-top: 15px;
  }
}
</style>
