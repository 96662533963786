<i18n lang="json">
{
	"ru": {
        "meter": "м",
        "distance_m": "{n} м",
        "distance_km": "{n} км",
        "route": "маршрут",
        "foot": "пешком",
        "on": "на",
        "byCar": "авто",
        "ropewayShow":"Показать все",
        "onFootByCar": "на авто",
        "ropewayHide":"Скрыть"
	},
	"en": {
        "meter": "m",
        "distance_m": "{n} m",
        "distance_km": "{n} km",
        "route": "route",
        "foot": "on foot",
        "on": "on",
        "byCar": "auto",
        "onFootByCar": "by car",
        "ropewayShow":"Show all",
        "ropewayHide":"Hide"
	}
}
</i18n>
<template>
  <div>
    <div
      class="map-padding"
      :class="{ active: activeMapPoint === 0 || activeMapPoint === 1 }"
    ></div>
    <div class="map-point-list">
      <div v-for="(item, key) in ropewayList" class="ropeway-item" :key="key">
        <div class="ropeway-title tmp-font--average_big_bold">{{ key }}</div>
        <div class="tmp-font--medium">
          <div
            class="map-point ropeway"
            :class="{ active: activeMapPoint === index }"
            v-for="(el, index) in item"
            :key="el.id"
          >
            <div class="map-point--name">
              <span class="icon-elevator"></span> {{ el.items[0].title }}
            </div>
            <div
              class="map-point--time"
              @click="setRoute(el.items[0], 'pedestrian', index)"
            >
              ~ {{ el.value }} <span class="icon-app-walk"></span>
            </div>
            <div
              class="map-point--foot-on-bycar"
              @click="setRoute(el.items[0], 'auto', index)"
            >
              {{ t("onFootByCar") }} <span class="icon-app-car-map"></span>
            </div>
            <div class="map-point--close" @click="removePoint()">
              <span class="icon-app-close-btn"></span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="map-padding"
        :class="{ active: activeMapPoint && activeMapPoint !== 1 }"
      ></div>
      <div class="ropeway-more" v-if="ropeway.length > 4">
        <button class="button" @click="ropewayToggle = !ropewayToggle">
          {{ ropewayToggleText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "RopewayDesktop",
  props: {
    ropeway: {
      type: Array,
      required: false,
    },
    activePointSights: {
      type: Number,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      activeMapPoint: undefined,
      ropewayToggle: true,
    };
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
    ...mapState("detail", ["locationObject"]),
    ropewayToggleText() {
      return this.ropewayToggle
        ? this.t("ropewayShow")
        : this.t("ropewayHide");
    },
    ropewayList() {
      if (!this.ropeway) return null;
      const ropeway = [...this.ropeway].sort((a, b) => {
        return a.items[0].title
          .toString()
          .localeCompare(b.items[0].title.toString());
      });
      const ropewayNew = this.ropewayToggle ? ropeway.slice(0, 4) : ropeway;

      return ropewayNew.reduce((acc, item) => {
        const index = item.items[0].group_title;
        if (acc[index]) {
          acc[index].push(item);
        } else {
          acc[index] = [item];
        }
        return acc;
      }, {});
    },
  },
  watch: {
    activeMapPoint(val) {
      this.$emit("setActivePointRopeway", val);
    },
    activePointSights(val) {
      if (val) {
        this.activeMapPoint = undefined;
      }
    },
  },
  methods: {
    ...mapMutations("detail", ["routeMapViewMutate"]),
    setRoute(to, routingMode, index) {
      this.activeMapPoint = index;
      let from = [];
      if (this.getObjectData.location.lat && this.getObjectData.location.lng) {
        from = [
          this.getObjectData.location.lat,
          this.getObjectData.location.lng,
        ];
      }

      let pointB = "";
      if (!to.lat || !to.lng) {
        pointB = `${this.locationObject.regionTitle}, ${this.getObjectData.location.location}, Горнолыжный подъёмник ${to.title}`;
      } else {
        pointB = [to.lat, to.lng];
      }

      this.$emit("route", {
        from: from,
        to: pointB,
        routingMode: routingMode,
      });
    },
    removePoint() {
      this.activeMapPoint = undefined;
      this.routeMapViewMutate(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-point-list {
  &--padding-bot {
    height: 14px;
    transition: height 0.3s ease;
    &.active {
      height: 0;
    }
  }
  &--padding-top {
    height: 14px;
    transition: height 0.3s ease;
    &.active {
      height: 0;
    }
  }
  .map-point {
    display: flex;
    flex: 1 1 auto;
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    align-items: center;
    position: relative;
    border-radius: 21px;
    padding: 4px 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #fff;
    overflow: hidden;
    transition: all 0.3s ease;

    &:nth-child(odd) {
      margin-right: 10px;
    }

    &:nth-child(even) {
      margin-left: 10px;
    }

    &.ropeway {
      width: 100%;
      max-width: 100%;
      &:nth-child(odd) {
        margin-right: 0;
      }

      &:nth-child(even) {
        margin-left: 0;
      }
      .icon-elevator {
        display: inline-block;
        margin-right: 10px;
        width: 14px;
        height: 14px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuOTk2OTUgMTAuNzcxNEM0Ljk5Njk1IDExLjU4NzkgNS42NTg4NyAxMi4yNDk4IDYuNDc1MzUgMTIuMjQ5OEg5LjY0NTkzQzEwLjQ2MjQgMTIuMjQ5OCAxMS4xMjQzIDExLjU4NzkgMTEuMTI0MyAxMC43NzE0VjEwLjA2MjVINC45OTY5NUw0Ljk5Njk1IDEwLjc3MTRaIiBmaWxsPSIjNDE3NTA1Ii8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNCAwVjAuOTY2MTRMOC40OTgxNCAzLjU0NTE1VjYuMTI1SDkuODEwNjRDMTEuNDk5MyA2LjEyNSAxMi44NzMxIDcuNDk5MDMgMTIuODczMSA5LjE4NzVWMTAuOTM3NUMxMi44NzMxIDEyLjYyNiAxMS40OTkzIDE0IDkuODEwNjQgMTRINi4zMTA2NEM0LjYyMTk2IDE0IDMuMjQ4MTQgMTIuNjI2IDMuMjQ4MTQgMTAuOTM3NVY5LjE4NzVDMy4yNDgxNCA3LjQ5OTAzIDQuNjIxOTYgNi4xMjUgNi4zMTA2NCA2LjEyNUg3LjYyMzE0VjMuOTU1MjhMMCA3LjUyODY0VjYuNTYyNUwxNCAwWk0xMS45OTgxIDEwLjkzNzVWOS4xODc1QzExLjk5ODEgNy45ODE0IDExLjAxNjcgNyA5LjgxMDY0IDdINi4zMTA2NEM1LjEwNDU0IDcgNC4xMjMxNCA3Ljk4MTQgNC4xMjMxNCA5LjE4NzVWMTAuOTM3NUM0LjEyMzE0IDEyLjE0MzYgNS4xMDQ1NCAxMy4xMjUgNi4zMTA2NCAxMy4xMjVIOS44MTA2NEMxMS4wMTY3IDEzLjEyNSAxMS45OTgxIDEyLjE0MzYgMTEuOTk4MSAxMC45Mzc1WiIgZmlsbD0iIzQxNzUwNSIvPjwvc3ZnPg==);
        background-position: center;
        filter: grayscale(1);
      }
    }

    &.active {
      padding: 8px 0 8px 15px;
      margin-top: 3px;
      margin-bottom: 3px;
      background-color: #f6f6f6;

      .map-point--close {
        right: 10px;
      }
      .map-point--name {
        &.long {
          width: 100%;
          max-width: 200px;
          @media only screen and (max-width: 1280px) {
            max-width: 130px;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 90px;
          }
        }
        @media only screen and (max-width: 1280px) {
          max-width: 130px;
        }
        @media only screen and (max-width: 1024px) {
          max-width: 90px;
        }
      }
    }

    &--color {
      width: 10px;
      height: 10px;
      min-width: 10px;
      border-radius: 20px;
      margin-right: 10px;
    }

    &--name {
      margin-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--time {
      cursor: pointer;
      color: #2d6cb4;
      margin-right: 5px;
      white-space: nowrap;
      .icon-app-walk {
        color: #444;
        font-size: 16px;
        vertical-align: bottom;
        margin-left: 3px;
      }
    }
    &--foot-on-bycar {
      cursor: pointer;
      color: #2d6cb4;
      margin-left: 16px;
      .icon-app-car-map {
        color: #444;
        font-size: 16px;
        vertical-align: bottom;
        margin-left: 3px;
      }
    }

    &--icon {
      .icon-app-walk {
        color: #444;
        font-size: 16px;
        vertical-align: bottom;
      }
    }

    &--close {
      cursor: pointer;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      right: -24px;
      transform: translateY(-50%);
      transition: right 0.3s ease;

      .icon-app-close-btn {
        color: #444444;
        font-size: 15px;
        vertical-align: bottom;
      }
    }
  }
  .ropeway-title {
    margin-bottom: 10px;
  }
  .ropeway-more {
    padding-top: 12px;
  }
}
.map-padding {
  height: 14px;
  transition: height 0.3s ease;
  &.active {
    height: 0;
  }
}
</style>
