<i18n lang="json">
{
	"ru": {
    "night": "за сутки|за {n} сутки|за {n} суток|за {n} суток",
    "rankSuper": "Суперхозяин"
	}
}
</i18n>

<template>
  <Carousel
    ref="slider"
    class="object-list"
    :initialSlide="initialSlide"
    :settings="{
      itemsToShow: 1,
      transition: 300,
      infiniteScroll: false,
      wheelControl: false,
    }"
    @slide="slide"
  >
    <template v-for="item in results" :key="item.id">
      <Slide>
        <router-link
          ref="link"
          class="block"
          target="_blank"
          @click="sendEventGtag('search_sa_m', { click: 'map_object' })"
          :to="{
            name: 'Detail',
            params: { id: item.id },
            query: {
              occupied: occupied,
              guests_adults: guests.adults,
              guests_childrens: guests.childrens.join(','),
              id: getQuery.query.id,
              type: getQuery.query.type,
            },
          }"
        >
          <img
            class="block--image"
            :src="item.media && item.media[0]?.replace('medium', 'small')"
            alt=""
          />
          <div class="block-line--wrapper">
            <div class="block-line block-labels" v-show="item.owner.permissions.includes('showSuperhostLabelAbility') || item.hotel">
              <span
                v-if="
                  item.owner.permissions.includes('showSuperhostLabelAbility')
                "
                class="superhost"
                >{{ t("rankSuper") }}</span
              >
              <div v-if="item.hotel" class="hotel">
                <span v-if="item.hotel.type">{{ item.hotel.type }}</span>
                {{ item.hotel.title }}
              </div>
            </div>
            <div class="block-line block-title">
              {{ item.title }}
            </div>
            <div class="block-line block-price">
              <div class="price">
                <span class="price-value" v-if="days === 1">
                  {{ formatedPrice(item.price.per_night) }}
                </span>
                <span class="price-value" v-else>
                  {{ formatedPrice(item.price.full) }}
                </span>
                <span class="price-day">
                  {{ t("night", days === 1 ? 0 : days) }}
                </span>
              </div>
              <div class="star">
                <template v-if="item.count_reviews <= 1">
                  <span
                      class="icon-app-star"
                      :class="{ grey: maxRatingExternal(item.external_reviews) === 0 }"
                  ></span>
                  {{ $filters.newRatingExternal(maxRatingExternal(item.external_reviews)) }}
                </template>
                <template v-else>
                  <span
                    class="icon-app-star"
                    :class="{ grey: item.rating === 0 }"
                  ></span>
                  {{ item.rating.toFixed(1) }}
                </template>
                <span class="star-grey">({{ item.count_reviews+countReviewsExternal(item.external_reviews) }})</span>
              </div>
            </div>
          </div>
        </router-link>
      </Slide>
    </template>
  </Carousel>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
// TODO: hooper -  обновить
import { Carousel, Slide } from "vue3-carousel";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { useI18n } from 'vue-i18n';

export default {
  name: "ObjectListMap",
  setup() {
    const { t } = useI18n();

    return {
      t,
      sendEventGtag,
    }
  },
  components: {
    Carousel,
    Slide,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState("search", ["selectedMapPointId", "guests"]),
    ...mapGetters("search", ["days", "occupied", "getQuery"]),
    ...mapGetters("detail", ["getObjectData"]),
    ...mapState("user", ["currency"]),
    initialSlide() {
      const slide = this.results.findIndex((v) => v.id == this.selectedMapPointId)
      return slide === -1 ? 0 : slide;
    },
    // filterResults() {
    //   return this.results.filter(item => item.id != this.getObjectData.id)
    // }
  },
  methods: {
    ...mapMutations("search", ["setSelectedMapPointId"]),
    slide({ currentSlide }) {
      sendEventGtag("search_sa_m", {
        click: "map_objects_slide",
      });
      const id = this.results[currentSlide]?.id;
      this.setSelectedMapPointId(id);
    },
    formatedPrice(price) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.currency.title,
        minimumFractionDigits: 0,
      }).format(price);
    },
    maxRatingExternal(external_reviews) {
      const externalReviews = external_reviews
      if (externalReviews) {
        const ratingAirbnb = externalReviews[0]["rating"] ? externalReviews[0]["rating"] : 0
        const ratingBooking = externalReviews[1]["rating"] ? externalReviews[1]["rating"] : 0

        return Math.max(ratingAirbnb, ratingBooking)
      } else {
        return 0
      }
    },
    countReviewsExternal(external_reviews) {
      const externalReviews = external_reviews
      if (externalReviews) {
        const countAirbnb = externalReviews[0]["count"] ? externalReviews[0]["count"] : 0
        const countBooking = externalReviews[1]["count"] ? externalReviews[1]["count"] : 0

        return countAirbnb + countBooking
      } else {
        return 0
      }
    },
  },
  watch: {
    selectedMapPointId() {
      this.$refs.slider.slideTo(
        this.results.findIndex((v) => v.id == this.selectedMapPointId)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.object-list.carousel {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 20px;
  height: 125px;
  width: 100%;
  width: calc(100% - 40px);
  text-align: inherit;

  :deep(.carousel__viewport) {
    overflow: unset;
  }
  .carousel__slide {
    padding: 0 5px;
    .block {
      height: 95px;
      min-width: 95px;
      width: 100%;
      background: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      overflow: hidden;
      display: flex;
      &-line {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 10px;
        &--wrapper {
          width: calc(100% - 95px);
          position: relative;
          padding: 0 0 10px 0;
          &::before {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            width: 10px;
            background: linear-gradient(270deg, white, #ffffff36);
            top: 0;
            bottom: 0;
          }
        }
      }
      &-labels {
        display: flex;
        margin-top: 10px;
      }
      &-title {
        margin-top: 10px;
        font-size: 16px;
        line-height: 18px;
      }
      &-price {
        display: flex;
        align-items: flex-end;
        position: absolute;
        bottom: 8px;
        width: 100%;
        .star {
          margin-left: auto;
          padding-right: 10px;
          // position: absolute;
          // right: 0;
          font-size: 13px;
          &-grey {
            color: #7e7e7e;
          }
        }
        .price {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &-value {
            font-size: 16px;
            font-weight: bold;
          }
          &-day {
            font-size: 14px;
            color: #444;
          }
        }
      }
      &--image {
        height: 100%;
        width: 95px;
        min-width: 95px;
        object-fit: cover;
        display: inline-block;
      }
    }
  }
}
.icon-app-star {
  color: #fdb12e;
  font-size: 18px;
  position: relative;
  top: 3px;
  margin: 0 -1px;
  &.grey {
    color: grey;
  }
}
.hotel {
  align-self: flex-start;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #444444;
  justify-content: center;
  font-size: 10px;
  line-height: 16px;
  padding: 0px 6px;
  text-transform: uppercase;
}
.superhost {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 16px;
  font-size: 10px;
  border-radius: 2px;
  padding: 0 6px;
  height: 18px;
  margin-right: 10px;
  color: #000;
  background-color: #f1c260;
  text-transform: uppercase;
}
</style>
