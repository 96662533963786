<i18n lang="json">
{
    "ru": {
        "getDirections": "Проложить маршрут",
        "locationNotFound": "местоположение не найдено",
        "determineMyLocation": "определить моё местоположение",
        "address": "Адрес",
        "metro": "Метро"
    },
    "en": {
        "getDirections": "Get directions",
        "locationNotFound": "location not found",
        "determineMyLocation": "determine my location",
        "address": "Address",
        "metro": "Metro"
    }
}
</i18n>
<template>
  <transition name="howToGet-fade">
    <div v-if="showPath" class="how-to-get">
      <div class="how-to-get-close" @click="close"></div>
      <div class="how-to-get-field-type">
        <span
          :class="{ active: typeSearch == 'city' }"
          @click="typeSearchBtn('city')"
          >{{ t("address") }}</span
        >
        <span
          v-if="metro"
          :class="{ active: typeSearch == 'metro' }"
          @click="typeSearchBtn('metro')"
          >{{ t("metro") }}</span
        >
      </div>
      <template v-if="typeSearch == 'city'">
        <div class="how-to-get-start">
          <div class="field">
            <input
              v-model="objectMap.pointA.address"
              type="text"
              @keyup.enter="keyupEnter"
              @keyup.up="keyupUp"
              @keyup.down="keyupDown"
            />
            <div class="field-reset" @click="pointResetA"></div>
          </div>
          <template v-if="objectMap.pointA.modal">
            <template v-if="objectMap.pointA.list.length > 0">
              <ul>
                <li
                  v-for="(value, i) in objectMap.pointA.list"
                  :class="{ active: i == objectMap.pointA.select }"
                  @click="apply(value.displayName)"
                >
                  {{ value.displayName }}
                </li>
              </ul>
            </template>
            <template v-else>
              <ul>
                <li class="disabled">
                  {{ t("locationNotFound") }}
                </li>
              </ul>
            </template>
          </template>
          <span @click="currentPosition">{{ t("determineMyLocation") }}</span>
        </div>
        <div class="how-to-get-end">
          <div class="field">
            <input
              v-model="objectMap.pointB.address"
              disabled="disabled"
              type="text"
            />
          </div>
        </div>
        <div class="how-to-get-btn">
          <button @click="getDirections">{{ t("getDirections") }}</button>
        </div>
      </template>
      <template v-if="typeSearch == 'metro' && metro.length">
        <div class="how-to-get-metro">
          <span
            v-for="item in metro"
            @click="getDirections(item.lat, item.lng)"
          >
            <span :key="item.station_name" class="icon-app-metro"></span>
            {{ item.station_name }}
          </span>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "HowToGetMapNew",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      typeSearch: "city",
      $currentRoute: "",
      metro: [],
      objectMap: {
        pointA: {
          location: "",
          address: "",
          coords: [],
          list: [],
          modal: false,
          select: -1,
        },
        pointB: {
          address: "",
          coords: [],
        },
      },
    };
  },
  watch: {
    "objectMap.pointA.address": function () {
      if (this.objectMap.pointA.list.length > 0) {
        this.objectMap.pointA.select = -1;
      }
    },
    showPath(val) {
      if (val) {
        this.open()
      }
    }
  },
  computed: {
    ...mapState("detail", ["objectData", "showPath", "objectCardMap"]),
  },
  methods: {
    ...mapMutations("detail", ["setShowPath", "setObjectMap"]),
    // автокомплит выбор города
    keyupEnter() {
      let thisEl = this;
      window.ymaps
        .suggest(this.objectMap.pointA.address)
        .then(function (items) {
          thisEl.objectMap.pointA.modal = true;
          thisEl.objectMap.pointA.list = items;
          if (thisEl.objectMap.pointA.select != -1) {
            thisEl.apply(
              thisEl.objectMap.pointA.list[thisEl.objectMap.pointA.select]
                .displayName
            );
          }
        });
    },
    keyupUp() {
      if (
        this.objectMap.pointA.list.length >= 1 &&
        this.objectMap.pointA.modal == true
      ) {
        this.objectMap.pointA.select--;
        if (this.objectMap.pointA.select <= 0) {
          this.objectMap.pointA.select = 0;
        }
      }
    },
    keyupDown() {
      if (
        this.objectMap.pointA.list.length >= 1 &&
        this.objectMap.pointA.list.length > this.objectMap.pointA.select &&
        this.objectMap.pointA.modal == true
      ) {
        this.objectMap.pointA.select++;
        if (this.objectMap.pointA.list.length == this.objectMap.pointA.select) {
          this.objectMap.pointA.select = this.objectMap.pointA.list.length - 1;
        }
      }
    },
    apply(val) {
      this.objectMap.pointA.address = val;
      this.objectMap.pointA.modal = false;
    },
    pointResetA() {
      this.objectMap.pointA.address = this.objectMap.pointA.location;
    },

    typeSearchBtn(val) {
      this.typeSearch = val;
    },
    open() {
      const objectMap = this.objectData.data.object;
      const objectCardMap = this.objectCardMap;

      this.metro = objectMap.metro;

      this.objectMap.pointA.location = objectMap.location.location;
      this.objectMap.pointA.address = objectMap.location.location;
      this.objectMap.pointB.address = objectCardMap.address;

      this.objectMap.pointB.coords = [objectCardMap.lat, objectCardMap.lng];
    },
    close() {
      this.setShowPath(false)
      this.typeSearch = "city";
    },

    // Проложить маршрут
    getDirections(lat, lng, valPointB) {
      let thisEl = this;
      let pointA =
        lat !== undefined && lng !== undefined
          ? [lat, lng]
          : this.objectMap.pointA.address;
      let pointB =
        valPointB !== undefined ? valPointB : this.objectMap.pointB.coords;
      let routingModeVal = "pedestrian";

      let multiRoute = new ymaps.multiRouter.MultiRoute(
        {
          referencePoints: [pointA, pointB],
          params: {
            routingMode: "auto",
          },
        },
        {
          boundsAutoApply: true,
        }
      );

      multiRoute.model.events.add("requestsuccess", function (event) {
        // если расстояние больше 5км, маршрут автомобильный
        if (
          multiRoute.getRoutes().get(0).properties.get("distance").value /
            1000 >
          5
        ) {
          routingModeVal = "auto";
        }
        let multiRouteMap = new ymaps.multiRouter.MultiRoute(
          {
            referencePoints: [pointA, pointB],
            params: {
              //Тип маршрутизации - пешеходная маршрутизация.
              routingMode: routingModeVal,
            },
          },
          {
            // Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
            boundsAutoApply: true,
          }
        );
        if (thisEl.currentRoute) {
          thisEl.$parent.myMap.geoObjects.remove(
            thisEl.currentRoute
          )
          thisEl.$parent.myMap.geoObjects.add(multiRouteMap);
        } else {
          thisEl.$parent.myMap.geoObjects.add(multiRouteMap);
        }

        thisEl.currentRoute = multiRouteMap;
        thisEl.$emit("update:modelValue", multiRouteMap);
      });
      this.setShowPath(false);
      this.typeSearch = "city";
      this.setObjectMap(null);
    },

    // определить моё местоположение
    currentPosition() {
      let thisEl = this;
      navigator.geolocation.getCurrentPosition(function (position) {
        window.ymaps
          .geocode([position.coords.latitude, position.coords.longitude], {
            results: 1,
          })
          .then(function (res) {
            thisEl.objectMap.pointA.address =
              res.geoObjects.get(0).getLocalities().join(", ") +
              ", " +
              res.geoObjects.get(0).properties.get("name");
            thisEl.objectMap.pointA.coords = [
              position.coords.latitude,
              position.coords.longitude,
            ];
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.howToGet-fade-enter-active,
.howToGet-fade-leave-active {
  transition: opacity 0.3s ease;
}
.howToGet-fade-enter,
.howToGet-fade-leave-to {
  opacity: 0;
}
.how-to-get {
  position: absolute;
  top: 62px;
  left: 10px;
  z-index: 100;
  background: #fff;
  width: 320px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  padding: 10px 15px;
  input[type="text"] {
    display: block;
    outline-style: none;
    margin: 0;
    width: 100%;
    height: 31px;
    padding: 4px 10px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    font-size: 14px;
    color: #444444;
  }
  &-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 28px;
    height: 28px;
    &:after,
    &:before {
      content: "";
      display: block;
      width: 15px;
      height: 1px;
      background: #000;
      position: absolute;
      top: 0;
      left: 0;
      margin: 14px 0 0 7px;
    }
    &:after {
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
    }
  }
  &-field-type {
    display: inline-flex;
    border: 1px solid #ddd;
    font-size: 14px;
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 25px;
    > span {
      cursor: pointer;
      display: block;
      padding: 5px 12px;
      &:nth-child(2) {
        border-left: 1px solid #ddd;
      }
      &.active {
        cursor: default;
        background: #f6f6f6;
      }
    }
  }
  &-start {
    margin-bottom: 15px;
    position: relative;
    input[type="text"] {
      padding-right: 38px;
    }
    span {
      cursor: pointer;
      color: #2d6cb4;
      font-size: 13px;
      &:hover {
        color: #f51449;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      border: 1px solid #ccc;
      position: absolute;
      top: 32px;
      border-radius: 3px;
      left: 0;
      right: 0;
      background: #fdfdfd;
      overflow: hidden;
      li {
        cursor: pointer;
        font-size: 14px;
        color: #000000;
        padding: 4px 14px;
        border-bottom: 1px solid #ccc;
        &:hover,
        &.active {
          background: #f0f0f0;
        }
        &:last-child {
          border-bottom: none;
        }
        &.disabled {
          cursor: default;
          &:hover,
          &.active {
            background: none;
          }
        }
      }
    }
    .field-reset {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 28px;
      height: 31px;
      &:after,
      &:before {
        content: "";
        display: block;
        width: 12px;
        height: 1px;
        background: #000;
        position: absolute;
        top: 0;
        left: 0;
        margin: 16px 0 0 4px;
        opacity: 0.6;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }
  }
  &-end {
    margin-bottom: 15px;
  }
  &-btn {
    button {
      cursor: pointer;
      outline-style: none;
      padding: 8px 20px;
      margin: 0;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      border-radius: 3px;
      background: #498bc3;
      border: 1px solid #3671a3;
      &:hover {
        background-color: #5c97c9;
        border-color: #498bc3;
      }
    }
  }
  &-metro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    > span {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #2d6cb4;
      font-size: 14px;
      margin-bottom: 4px;
      &:hover {
        color: #f51449;
      }
      .icon-app-metro {
        color: #e10600;
        font-size: 17px;
        margin-right: 6px;
      }
    }
  }
}
</style>
