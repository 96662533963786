<i18n lang="json">
{
  "ru": {
    "breakfast": "завтрак",
    "dinner": "обед",
    "supper": "ужин",
    "price_0": "входит в стоимость проживания",
    "price_1": "оплачивается отдельно",
    "nofood": "не предоставляется",
    "sweden": "шведский стол",
    "menu": "по меню",
    "complex": "комплексный",
    "all_inclusive": "всё включено",
    "switchedOn": "включён",
    "included": "включено: ",
    "haveFood": "есть питание",
    "meals": {
      "included": "{0} включён в стоимость проживания | {0} и {1} включены в стоимость проживания | {0}, {1}, {2} включены  в стоимость проживания",
      "breakfast": "завтрак",
      "dinner": "обед",
      "supper": "ужин"
    },
    "withMeals": "С питанием"
  },
  "en": {
    "breakfast": "breakfast",
    "dinner": "lunch",
    "supper": "dinner",
    "price_0": "included in the cost of living",
    "price_1": "paid separately",
    "nofood": "not provided",
    "sweden": "buffet",
    "menu": "by menu",
    "complex": "complex",
    "all_inclusive": "all inclusive",
    "switchedOn": "switched on",
    "included": "included: ",
    "haveFood": "have food",
    "meals": {
      "included": "{0} is included in the price | {0} and {1} are included in the price | {0}, {1}, {2} are included in the price",
      "breakfast": "breakfast",
      "dinner": "lunch",
      "supper": "dinner"
    },
    "withMeals": "With meals"
  }
}
</i18n>
<template>
  <div v-if="meals" class="meal" @click="$emit('clickMeal', true)">
    <span class="icon-app-meal"></span>
    <span class="meal--text">{{ meals }}</span>
  </div>
</template>

<script>
// логика плашки описана в задаче VUECLIENT-744
import { useI18n } from 'vue-i18n';

export default {
  name: "InfoMeal",
  props: {
    meal: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    meals() {
      if (!this.meal?.meal_types) return null;

      if(this.meal.is_all_inclusive) {
        return this.t('all_inclusive')
      }

      let included = [];
      let unincluded = [];
      this.meal.meal_types.forEach((v) => {
        if (v.kind === "nofood" || v.kind === "") return false;
        if (v.price === 0) {
          included.push(this.t(`meals.${v.name}`));
        } else if (v.price === 1) {
          unincluded.push(this.t(`meals.${v.name}`));
        }
      });

      const ucFirst = (str) => {
        return str[0].toUpperCase() + str.slice(1);
      };

      if (included.length) {
        return ucFirst(
          this.t("meals.included", included, included.length - 1)
        );
      }
      if (unincluded.length) {
        return `${unincluded.join(", ")} ${this.t("price_1")}`;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.meal {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #417505;
  font-size: 10px;
  height: 28px;
  padding: 0 15px;
  border-radius: 14px;
  border: solid 1px #417505;
  margin-right: 10px;
  &--text {
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .icon-app-meal {
    margin-right: 14px;
    font-size: 16px;
  }
}
</style>
