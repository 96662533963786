<template>
  <div class="children">
    <select v-model="select">
      <option v-for="(item, index) in 18" :key="index" :value="index">
        {{ index }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectAge",
  props: {
    selected: {
      type: Number,
      default: 10,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      select: 10,
    };
  },
  watch: {
    select(val) {
      this.$emit("update:modelValue", {
        index: this.index,
        val: val,
      });
    },
  },
  mounted() {
    this.select = this.selected;
  },
};
</script>

<style lang="scss" scoped>
.children {
  margin: 0 10px 10px 0;
  &:nth-child(3n) {
    margin-right: 0;
  }
  select {
    display: block;
    outline-style: none;
    padding: 0 0 0 12px;
    margin: 0;
    width: 62px;
    height: 31px;
    font-size: 14px;
    color: #444444;

    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-appearance: none;
    background-image: url("~/assets/img/arrow-select.svg");
    background-position: right 12px top 6px;
    background-repeat: no-repeat;
    line-height: 1em;
    /* for FF */
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    /* for IE */
    -ms-appearance: none;
    appearance: none !important;
    &::-ms-expand {
      display: none;
    }
  }
}
</style>
