<i18n lang="json">
{
	"ru": {
    "titleMap": "Расположение",
    "viewResults": "показать другие объекты рядом"
	},
	"en": {
    "titleMap": "Location",
    "viewResults": "show other objects"
	}
}
</i18n>
<template>
  <div
    class="map"
    v-observe-visibility="{
      callback: (isVisible, entry) => visibilityChanged(isVisible, entry),
      throttle: 0,
      intersection: {
        threshold: 0.00001,
      },
    }"
  >
    <div class="map__main">
      <div class="map__layout">
        <img
          class="map__image"
          :src="imgMap"
          @mousedown="openMapGa"
        >
        <div class="map__point" @mousedown="openMapGa">
          <span class="icon-app-point-home">
            <span class="path1"></span>
            <span class="path2"></span>
          </span>
        </div>
        <div @click.prevent.stop="hrefToGet('small')" class="map__navi">
          <img src="~/assets/img/map/yandex-map.png">
          <span>Как добраться?</span>
        </div>
        <div class="map__panorama" @click="panoramaShow('small')">
          <span class="icon-app-degrees" />
        </div>
      </div>
      <div class="map__options">
        <div class="map__location">
          <span>{{ getObjectData.location.location }}</span>
          <span v-if="getObjectData.location.address">,&nbsp;{{ getObjectData.location.address }}</span>
        </div>
        <div class="map__functional" v-if="
          metroLength > 0
          || getObjectData.location.seas
          || getObjectData.location.relations && getObjectData.location.relations.length > 0
        ">
          <Metro v-if="metroLength > 0" @route="setRoute"/>
          <Sea v-else-if="getObjectData.location.seas" @route="setRoute"/>
          <Ropeway v-else-if="getObjectData.location.relations && getObjectData.location.relations.length > 0" @route="setRoute"/>
          <MapSights @setRoute="setRoute" :modal-map-view="mapView" />
        </div>
      </div>
    </div>
    <Modal ref="modal" theme="light" v-model="mapView" :title="t('titleMap')" @close="closeModal()" >
      <div class="map__modal">
        <template v-if="panoramaView">
          <Panorama
            :settings="{
              apiKey,
            }"
            :coords="[
              getObjectData.location.lat,
              getObjectData.location.lng
            ]"
            @panoramaClose="panoramaView = false"
          />
        </template>
        <template v-else>
          <div @click.prevent.stop="hrefToGet('big')" class="map__navi">
            <img src="~/assets/img/map/yandex-map.png">
            <span>Как добраться?</span>
          </div>
          <div class="map__panorama" @click="panoramaShow('big')">
            <span class="icon-app-degrees" />
          </div>
          <MapLayout :results="resultsFilter" :isResults="isResults" :modal-map-view="mapView" />
          <div class="map__result-switch">
            <p class="result-switch__text">{{ t('viewResults') }}</p>
            <Switcher
              v-model="isResults"
              :checked-value="true"
              :unchecked-value="false"
              @onClick="
                sendEventGtag('object_sa_m', {
                  click: 'map_big_others',
                })
              "
            />
          </div>
        </template>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import _ from "lodash";

import { event as sendEventGtag } from "@/composables/gtag/index.js";
import MapLayout from './MapLayout'
import Panorama from './Panorama'
import Metro from './Metro'
import Sea from './Sea'
import Ropeway from './Ropeway'
import Modal from "@sutochno/modal"
import Switcher from "@sutochno/switcher";
import MapSights from "./MapSights.vue";

import { HTTP } from "@/components/api/index.js";

import "vue3-carousel/dist/carousel.css";
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import useStoreWatchersDetailMap from '@/composables/detail/map/useStoreWatchersDetailMap';

export default {
  name: "Map",
  setup() {
    const { t } = useI18n();

    //TODO: nuxt проверить актуален ли вотчер
    useStoreWatchersDetailMap();

    return {
      t,
      sendEventGtag,
    }
  },
  components: {
    MapLayout,
    Panorama,
    Metro,
    Sea,
    Ropeway,
    Modal,
    Switcher,
    MapSights,
  },
  watch:{
    mapView(val) {
      if(val) {
          this.results = {};
          this.status = {
            1: "loading",
          };
          this.pageNum = 1;
          this.getObjects();
      }
    }
  },
  methods: {
    ...mapActions("detail", ["setReviewsMore", "setRouteMap", "getDistanceSights"]),
    ...mapMutations("detail", ["routeMapViewMutate"]),
    ...mapMutations("search", ["updateSwitchMap"]),
    openMap() {
      if(this.routeMapView)
        this.routeMapViewMutate(false);
      this.mapView = true;
    },
    openMapGa() {
      this.openMap();
      sendEventGtag("object_sa_m", {
        click: "map_small",
      });
    },
    setRoute(e) {
      this.mapView = true;
      this.$nextTick(() => {
        if(this.mapView) {
          this.setRouteMap(e);
          this.routeMapViewMutate(true);
        }
      })
    },
    hrefToGet(map) {
      window.open(`yandexnavi://build_route_on_map?lat_to=${
          this.getObjectData.location.lat
        }&lon_to=${
          this.getObjectData.location.lng
        }`, "_blank")
      if(map === 'small')
        sendEventGtag("object_sa_m", {
          click: "map_small_how",
        });
      else if(map === 'big')
        sendEventGtag("object_sa_m", {
          click: "map_big_how",
        });
    },
    panoramaShow(map) {
      this.mapView = true;
      this.panoramaView = true;
      if(map === 'small')
        sendEventGtag("object_sa_m", {
          click: "map_small_panorama",
        });
      else if(map === 'big')
        sendEventGtag("object_sa_m", {
          click: "map_big_panorama",
        });
    },
    closeModal() {
      this.mapView = false;
      this.panoramaView = false;
      this.isResults = false;
    },
    clearFilters() {
      this.$store.dispatch("filter/resetFilter");
      sendEventGtag("search_sa_m", {
        click: "btn_clear_search",
      });
      // Востанавливаем BBOX локации
      this.$store.commit("search/setBound", this.location.bbox);
    },
    loadPage(page) {
      if (!this.results.hasOwnProperty(page)) this.getObjects(page);
      if (
        Math.ceil(this.totalFound / this.perPage) > page + 1 &&
        !this.results.hasOwnProperty(page + 1)
      ) {
        _.debounce(() => {
          this.getObjects(page + 1);
        }, 500)();
      }
    },
    async getObjects(pageNum = 1) {
      this.status[pageNum] = "loading";
      let request = {
        ...this.objectFilters,
        // location_type: this.location.type,
        // location_id: this.location.id,

        currency_id: this.currency.id,
        "NE[lat]": this.bound ? this.bound.NE.lat : "",
        "NE[lng]": this.bound ? this.bound.NE.lng : "",
        "SW[lat]": this.bound ? this.bound.SW.lat : "",
        "SW[lng]": this.bound ? this.bound.SW.lng : "",
        // "size[x]": 865,
        // "size[y]": 421,
        zoom: 13,
        count: this.perPage,
        offset: (pageNum - 1) * this.perPage,
      };
      if (this.cancelSource && pageNum == 1) {
        this.cancelSource.cancel("Operation canceled by the user.");
      }
      this.cancelSource = axios.CancelToken.source();
      HTTP.get("json/search/searchObjects", {
        headers: {
          "api-version": String(null),
        },
        params: request,
        cancelToken: this.cancelSource.token,
        cache: false,
      })
        .then((response) => ({
          result: response.data.data.objects,
          adblock: Number(
            response.data.data.ad_block ||
              0 ||
              JSON.parse(localStorage.getItem("VUE_APP_ADBLOCK") || "0")
          ),
          count: response.data.data.total_count || response.data.data.count,
        }))
        .then(({ result, count, adblock }) => {
          this.$store.commit("search/setTotalFound", count);
          if (pageNum == 1) {
            this.results = {};
            this.status = {
              1: "loading",
            };
          }
          result.forEach((elem, index) => {
            elem.price.full = Math.round(elem.price.full) || null;
            elem.price.per_night = Math.round(elem.price.per_night) || null;
          });
          this.adblock = adblock;
          this.results[pageNum] = result;

          if (count == 0 || count == this.results.length) {
            this.status[pageNum] = "complete";
          } else {
            this.status[pageNum] = "loaded";
          }
        })
        .catch((error) => {
          this.status[pageNum] = "fail";
          console.warn("Ошибка при загруки выдачи:", error);
        });
    },
    visibilityChanged(isVisible) {
      if (isVisible && !this.distanceSights) {
        this.getDistanceSights(this.$route.params.id);
      }
    },
  },
  computed: {
    ...mapGetters("detail", ["getObjectData", "getObjectReviews", "getReviews", "avgRating", "getRouteMap"]),
    ...mapState("detail", ["routeMapView", "distanceSights"]),
    ...mapState("search", [
      "filters",
      "sortings",
      "totalFound",
      "location",
      "bound",
      "switchMap",
    ]),
    ...mapState("user", ["currency"]),
    ...mapGetters("search", ["occupied", "maxGuests"]),
    imgMap() {
      return `https://static-maps.yandex.ru/1.x/?ll=${this.getObjectData.location.lng},${this.getObjectData.location.lat}&z=16&l=map&size=650,450`
    },
    resultsFilter() {
      if(this.results[1])
        return this.results[1].filter(item => item.id != this.getObjectData.id)
      else
        return []
    },
    metroLength() {
      return this.getObjectData?.metro?.length || 0;
    }
  },
  data() {
    return {
      apiKey: process.env.NODE_ENV === "development"
          ? "ed03bb3a-3f94-48b0-a552-7a2b6d165870"
          : this.$route.name === "Detail" ? "991db111-5570-4d7b-975b-e39c3c95bf34"
          : "bdf0aff7-5600-485a-88c7-7c764a34c9f3",
      mapView: false,
      panoramaView: false,
      isResults: false,
      adblock: 0, // Рекламная позиция 0 = выкл
      results: [],
      showLoader: false,
      max_results: process.env.NODE_ENV === "production" ? 999999999 : 500, // МАКСИУМ ОБЪЕКТОВ НА СТРАНИЦЕ ПОИСКА
      pageNum: 1,
      perPage: 11,
      status: {
        1: "loading",
      },
      cancelSource: null,
      modalSearch: false,
      hideSearch: false,
      lastScrollTop: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.map {
  &__layout {
    width: 100%;
    height: 200px;
    // height: 500px;
    position: relative;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__point {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);
    .icon-app-point-home {
      font-size: 40px;
    }
  }
  &__navi,
  &__panorama {
    position: absolute;
    bottom: 10px;
    z-index: 1;
  }
  &__navi {
    color: #000;
    left: 20px;
    background-color: #fff;
    padding: 4px 5px;
    border-radius: 4px;
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.2);
    img {
      height: 18px;
      margin-right: 4px;
    }
  }
  &__panorama {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 20px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

    .icon-app-degrees {
      font-size: 18px;
    }
  }
  &__options {
    padding: 20px;
    background-color: #fff;
  }
  &__location {

  }
  &__functional {
    margin-top: 10px;
  }
  &__modal {
    height: 100%;
    position: relative;

    .map {
      &__panorama,
      &__navi {
        top: 10px;
        bottom: initial;
      }
      &__navi {
        top: 22px;
        bottom: initial;
      }
    }
  }
  &__result-switch {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .result-switch {
      &__text {

      }
    }
  }
}
</style>
