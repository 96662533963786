<i18n lang="json">
{
	"ru": {
        "wolkTime": "менее минуты | {n} минута | {n} минуты | {n} минут"
	},
	"en": {
        "wolkTime": "less than a minute | {n} minute | {n} minutes | {n} minutes "
	}
}
</i18n>
<template>
  <div>
    <div
      class="map-padding"
      :class="{ active: activeMapPoint === 0 || activeMapPoint === 1 }"
    ></div>
    <div class="map-point-list">
      <div
        class="map-point"
        :class="{ active: activeMapPoint === index }"
        v-for="(item, index) in metroList"
        v-show="item.walk_time < 60"
        :key="index"
      >
        <div
          class="map-point--color"
          :style="{ background: item.line_color }"
        ></div>
        <div
          class="map-point--name"
          :class="{ long: item.station_name.length > 18 }"
        >
          {{ item.station_name }}
        </div>
        <div
          class="map-point--time"
          @click="setRoute(item, 'pedestrian', index)"
        >
          ~ {{ t("wolkTime", item.walk_time) }}
        </div>
        <div class="map-point--icon">
          <span class="icon-app-walk"></span>
        </div>
        <div class="map-point--close" @click="removePoint()">
          <span class="icon-app-close-btn"></span>
        </div>
      </div>
    </div>
    <div
      class="map-padding"
      :class="{ active: activeMapPoint && activeMapPoint !== 1 }"
    ></div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "MetroDesktop",
  props: {
    isShowMetro: {
      type: Boolean,
      required: false,
    },
    activePointSights: {
      type: Number,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      activeMapPoint: undefined,
    };
  },
  mounted() {
    if (this.isShowMetro && this.metroList.length) {
      this.setRoute(this.metroList[0], 'pedestrian', 0)
    }
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
    metroList() {
      return this.getObjectData.metro;
    },
  },
  watch: {
    activeMapPoint(val) {
      this.$emit("setActivePointMetro", val);
    },
    activePointSights(val) {
      if (val) {
        this.activeMapPoint = undefined;
      }
    },
  },
  methods: {
    ...mapMutations("detail", ["routeMapViewMutate"]),
    setRoute(item, routingMode, index) {
      this.activeMapPoint = index;
      this.$emit("route", {
        from: [
          this.getObjectData.location.lat,
          this.getObjectData.location.lng,
        ],
        to: [
          item.lat,
          item.lng,
        ],
        routingMode: routingMode,
      });
      this.$gtag.event("build_route", { click: "metro" });
    },
    removePoint() {
      this.activeMapPoint = undefined;
      this.routeMapViewMutate(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-point-list {
  display: flex;
  flex-wrap: wrap;
  .map-point {
    display: flex;
    flex: 1 1 auto;
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    align-items: center;
    position: relative;
    border-radius: 21px;
    padding: 4px 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #fff;
    overflow: hidden;
    transition: all 0.3s ease;

    &:nth-child(odd) {
      margin-right: 10px;
    }

    &:nth-child(even) {
      margin-left: 10px;
    }

    &.active {
      padding: 8px 0 8px 15px;
      margin-top: 3px;
      margin-bottom: 3px;
      background-color: #f6f6f6;

      .map-point--close {
        right: 10px;
      }
      .map-point--name {
        &.long {
          width: 100%;
          max-width: 200px;
          @media only screen and (max-width: 1280px) {
            max-width: 130px;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 90px;
          }
        }
        @media only screen and (max-width: 1280px) {
          max-width: 130px;
        }
        @media only screen and (max-width: 1024px) {
          max-width: 90px;
        }
      }
    }

    &--color {
      width: 10px;
      height: 10px;
      min-width: 10px;
      border-radius: 20px;
      margin-right: 10px;
    }

    &--name {
      margin-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--time {
      cursor: pointer;
      color: #2d6cb4;
      margin-right: 5px;
      white-space: nowrap;
      .icon-app-walk {
        color: #444;
        font-size: 16px;
        vertical-align: bottom;
        margin-left: 3px;
      }
    }
    &--foot-on-bycar {
      cursor: pointer;
      color: #2d6cb4;
      margin-left: 16px;
      .icon-app-car-map {
        color: #444;
        font-size: 16px;
        vertical-align: bottom;
        margin-left: 3px;
      }
    }

    &--icon {
      .icon-app-walk {
        color: #444;
        font-size: 16px;
        vertical-align: bottom;
      }
    }

    &--close {
      cursor: pointer;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      right: -24px;
      transform: translateY(-50%);
      transition: right 0.3s ease;

      .icon-app-close-btn {
        color: #444444;
        font-size: 15px;
        vertical-align: bottom;
      }
    }
  }
}
.map-padding {
  height: 14px;
  transition: height 0.3s ease;
  &.active {
    height: 0;
  }
}
</style>
