<i18n lang="json">
{
    "ru": {
        "from": "от",
        "byOneNight": "за сутки",
        "total": "Всего {price}",
        "night": "за сутки|за {n} сутки|за {n} суток|за {n} суток"
    },
    "en": {
        "from": "from",
        "byOneNight": "per day",
        "total": "Total {price}",
        "night": "per day | for {n} days | for {n} days | for {n} days"
    }
}
</i18n>
<template>
  <div class="price-bronevik" :class="textAlign">
    <template v-if="isMap">
      <div class="price-bronevik--one">
        {{ t("from") }} <span>{{ priceAll }}</span>
        {{ t("night", countDay === 1 ? 0 : countDay) }}
      </div>
    </template>
    <template v-else>
      <div class="price-bronevik--one">
        <span>{{ priceOneDay }}</span> {{ t("byOneNight") }}
      </div>
      <div v-if="countDay > 1" class="total-price">{{ t("total", { price: priceAll }) }}</div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "BasePriceBronevik",
  props: {
    priceBronevik: {
      type: Number,
      default: 0,
    },
    textAlign: {
      type: String,
      default: "",
    },
    isMap: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapState("search", ["checkIn", "checkOut"]),
    ...mapState("user", ["currency"]),
    countDay() {
      if (this.checkIn && this.checkOut) {
        let checkIn = new Date(this.checkIn).getTime();
        let checkOut = new Date(this.checkOut).getTime();
        return (checkOut - checkIn) / 86400000;
      } else {
        return 1;
      }
    },
    priceOneDay() {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.currency.title,
        minimumFractionDigits: 0,
      }).format(this.priceBronevik / this.countDay);
    },
    priceAll() {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.currency.title,
        minimumFractionDigits: 0,
      }).format(this.priceBronevik);
    },
  },
};
</script>

<style lang="scss" scoped>
.price-bronevik {
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  &.right {
    text-align: right;
  }
  &--one {
    span {
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
    }
  }
  .total-price {
    color: #717171;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    padding-top: 3px;
  }
}
</style>
