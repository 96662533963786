export default (watchers = []) => {
  let isWatching = true;

  const unwatch = () => {
    if (isWatching) {
      watchers.forEach((unwatch) => unwatch());
    }
    isWatching = false;
  };

  onBeforeRouteLeave(() => {
    unwatch();
  });

  onUnmounted(() => {
    unwatch();
  });
}