<template>
  <ul class="loader">
    <li class="loader__dot"></li>
    <li class="loader__dot"></li>
    <li class="loader__dot"></li>
  </ul>
</template>

<script>
export default {
  name: "MapLoader",
};
</script>

<style lang="scss" scoped>
.loader-dots__item {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #f51449;
  animation: dots 1.5s ease-in-out infinite;
  .bg-gray & {
    background: #444;
  }
  &:nth-child(2){
    animation-delay: .5s;
  }
  &:nth-child(3){
    animation-delay: 1s;
  }
}



.loader{
  margin: 0;
  padding: 0;
  height: 26px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 25px;
  background: var(--shades-dark-4-modal-bg, rgba(0, 0, 0, 0.50));
  backdrop-filter: blur(40px);
  list-style: none;

  &__dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    animation: dots 1.5s ease-in-out infinite;
    transform: scale(0);

    &:nth-child(2){
        animation-delay: .25s;
    }
    &:nth-child(3){
        animation-delay: .5s;
    }
  }
}
@keyframes dots {
  0%, 60%, 100% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
}
</style>
