<i18n lang="json">
{
	"ru": {
    "wolkTime": "менее минуты пешком | {n} минута пешком | {n} минуты пешком | {n} минут пешком"
	},
	"en": {
    "wolkTime": "less than a minute on foot | {n} minute on foot | {n} minutes on foot | {n} minutes on foot"
	}
}
</i18n>
<template>
  <div class="metro">
    <div class="metro__item" v-for="(metro, i) in metroList" :key="i">
      <div class="metro__title">
        <span class="metro__circle" :style="{ 'background-color': metro.line_color }" />
        <span class="metro__text">{{ metro.station_name }}</span>
      </div>
      <div class="metro__distance">
        <button class="button button_link" @click="setRoute(metro, 'pedestrian')">~ {{ t('wolkTime', metro.walk_time) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "Metro",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
    metroList() {
      return this.getObjectData.metro.filter((item, i) => i < 3)
    }
  },
  methods: {
    setRoute(metro, routingMode) {
      this.$emit('route', {
        from: [this.getObjectData.location.lat, this.getObjectData.location.lng],
        to: [metro.lat, metro.lng],
        routingMode: routingMode
      });
      this.$gtag.event("build_route", { click: "metro" });
      this.$emit("setActivePointMetro", metro);
    }
  }
};
</script>

<style lang="scss" scoped>
.metro {
  &__item {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    &:first-child {
      margin-top: 0;
    }
  }

  &__circle {
    display: block;
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  &__text {
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__title {
    display: flex;
    align-items: center;
            text-overflow: ellipsis;
    overflow: hidden;
  }
  &__distance {
    white-space: nowrap;
    .button_link {
      font-size: 12px;
    }
  }
}
</style>
