<i18n lang="json">
{
	"ru": {
    "wolkTime": "менее минуты пешком | {n} минута пешком | {n} минуты пешком | {n} минут пешком",
    "meter": "м",
    "kmeter": "км",
    "hint_sea": "Указано прямое расстояние до моря. Реальное расстояние в пути может быть больше.",
    "distance_meters": "{distance} м {to}",
    "distance_kilometers": "{distance} км {to}"
	},
	"en": {
    "wolkTime": "less than a minute on foot | {n} minute on foot | {n} minutes on foot | {n} minutes on foot",
    "meter": "m",
    "kmeter": "km",
    "hint_sea": "Showed direct distance calculated automatically; real distance to the sea may be longer.",
    "distance_meters": "{distance} m {to}",
    "distance_kilometers": "{distance} km {to}"
	}
}
</i18n>
<template>
  <div class="sea" v-if="sea">
    <div class="sea__icon">
      <span class="icon-app-sea"></span>
    </div>
    <div class="sea__text">
      <span
        v-tooltip="{
          content: t('hint_sea'),
          popperClass: 'v-popper__popper--max-width-300',
          triggers: ['click'],
        }"
      >
        {{ sea }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import seas from '@/assets/json/seas.json';
import { useI18n } from 'vue-i18n';

export default {
  name: "Sea",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
    sea() {
      if (!this?.getObjectData?.location?.seas.length) return null;

      const distance = this.getObjectData?.location?.seas[0]?.distance;
      const to = seas[this.getObjectData?.location?.seas[0]?.sea]?.to ? seas[this.getObjectData?.location?.seas[0]?.sea]?.to : "";

      if (distance < 50) {
        return this.t("distance_meters", {
          distance: "< 50",
          to
        });
      } else if (distance < 991) {
        return this.t("distance_meters", {
          distance: (Math.ceil(distance / 10) * 10).toLocaleString(),
          to
        });
      } else {
        return this.t("distance_kilometers", {
          distance: (
            (Math.round(distance / 100) * 100) /
            1000
          ).toLocaleString(),
          to
        });
      }
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.sea {
  display: flex;
  // justify-content: center;
  align-items: center;
  &__icon {
    .icon-app-sea {
      color: #417505;
      font-size: 13px;
    }
  }
  &__text {
    font-size: 14px;
    color: #000;
    margin-left: 10px;
  }
}
</style>
