<i18n lang="json">
{
  "ru": {
    "cleaningPrice": "плата за уборку",
    "byOneNight": "за сутки",
    "night": "0|за {n} сутки|за {n} суток|за {n} суток",
    "order": "По запросу",
    "prePay": "Без предоплаты",
    "today": "сегодня",
    "hintText": "Стоимость {data} при заезде {checkIn}",
    "totalNight": "Всего",
    "loading": "загрузка..."
  },
  "en": {
    "cleaningPrice": "for cleaning",
    "byOneNight": "per day",
    "night": "0|per {n} day|per {n} days|per {n} days",
    "order": "On request",
    "prePay": "Without prepayment",
    "today": "today",
    "hintText": "Cost {data} at check-in",
    "totalNight": "total",
    "loading": "loading..."
  }
}
</i18n>
<template>
  <div class="price-order">
    <template v-if="hotSalePrice">
      <div class="discount-wrap">
        <span class="discount-percent">
          %
        </span>
        <span class="discount-price">
          {{ formatedCurrensies(fullPricePerNight) }}
        </span>
      </div>
    </template>
    <div>
      <!-- цена за сутки -->
      <div class="price" :class="{ discount: hotSalePrice }">
        <i v-if="data.booking_now === true" class="icon-fast-booking"></i>
        <span v-if="hotSalePrice">{{ formatedCurrensies(averagePricePerNight) }}</span>
        <span v-else>{{ formatedCurrensies(fullPricePerNight) }}</span>
        <span class="price-text"> {{ t("byOneNight") }}</span>
      </div>
      <!-- цена за сутки -->

      <!--  общая цена за все дни -->
      <div v-if="days > 1 && totalPrice !== fullPricePerNight" class="price-total">
        <span class="price-text">{{ t("totalNight") }}</span>
        {{ formatedCurrensies(totalPrice) }}

        <!--        <v-popover-->
        <!--          offset="5"-->
        <!--          placement="bottom"-->
        <!--          trigger="click"-->
        <!--          class="price-popover"-->
        <!--          popoverClass="tooltip&#45;&#45;price-tooltip"-->
        <!--        >-->
        <!--          <i class="price-icon icon-app-question" @click.prevent="getPrice"></i>-->
        <!--          <div class="popover-text" slot="popover">-->
        <!--            <template  v-if="prices">-->
        <!--            <div class="popover-text__price"  v-for="price in detailPrice">-->
        <!--              <p>-->
        <!--                {{ formatedCurrensies(price.cost) }} х {{t('night', price.cnt)}}-->
        <!--              </p>-->
        <!--              <p>-->
        <!--                {{ formatedCurrensies(price.cost*price.cnt) }}-->
        <!--              </p>-->
        <!--            </div>-->
        <!--            </template>-->
        <!--            <div v-else>{{t('loading')}}</div>-->
        <!--            <div class="popover-text__price" v-if="cleaningPrice">-->
        <!--              <div>-->
        <!--                {{ formatedCurrensies(cleaningPrice) }}-->
        <!--                <div>{{ t("cleaningPrice") }}</div>-->
        <!--              </div>-->
        <!--              <p>-->
        <!--                {{ formatedCurrensies(cleaningPrice) }}-->
        <!--              </p>-->
        <!--            </div>-->

        <!--            <div v-close-popper class="tooltip-close"></div>-->
        <!--          </div>-->
        <!--        </v-popover>-->
      </div>
      <!--  общая цена за все дни -->
    </div>
  </div>
</template>
<script>
import HTTP from "@/components/api/http-common";
import getPricesAndAvailabilities from "@/components/api/objects/getPricesAndAvailabilities.js";
import { mapState, mapGetters } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "PriceOrder",
  props: {
    data: {
      type: Object,
      default: false,
    },
    cleaningPrice: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      prices: null,
    };
  },
  methods: {
    getPrice() {
      let objectData = new FormData();
      objectData.append("objects[]", [this.data.id]);
      objectData.append("date_begin", new Date(this.checkIn).toISOString());
      objectData.append("date_end", new Date(this.checkOut).toISOString());
      HTTP.post("json/objects/getPricesAndAvailabilities", objectData, {
        headers: {},
        cache: false,
      }).then((res) => {
        const { data: data } = res;
        this.prices = data.data[this.data.id].data;
      });
    },
    formatedCurrensies(price) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.currency.title,
        minimumFractionDigits: 0,
      }).format(price);
    },
  },
  computed: {
    ...mapState({
      screenSize: (state) => state.user.viewPort,
    }),
    ...mapGetters("search", ["occupied", "days"]),
    ...mapState("search", ["checkIn", "checkOut"]),
    ...mapState("user", ["currency"]),
    totalPrice() {
      return this.data.price.full;
    },
    fullPricePerNight() {
      return this.data.price.full_per_night || this.data.price.without_discounts;
    },
    averagePricePerNight() {
      return this.data.price.average_per_night || this.data.price.per_night;
    },
    hintCheckIn() {
      return (
        (this.checkIn &&
          this.checkIn.toLocaleString(this.$i18n.locale, {
            day: "numeric",
            month: "long",
          })) ||
        this.t("today")
      );
    },
    detailPrice() {
      return this.prices ? this.prices.detail : null;
    },
    hotSalePrice() {
      return this.data.hot_sale &&
        Number(this.data.hot_sale.price_per_night) > 0
        ? Number(this.data.hot_sale.price_per_night)
        : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.price-order {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: clip;
  color: #212529;
  &-vertical {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1;

    padding-top: 20%;
    justify-content: center;
    height: 100%;
    .days {
      margin-bottom: auto;
    }
  }
  &.booked {
    color: #909294;
  }
  .icon-fast-booking {
    display: inline-block;
    margin-right: 6px;
    width: 10px;
    height: 15px;
    background: url("~/assets/img/icon/flash.svg") center center no-repeat;
    background-size: contain;
    fill: #f51449;
  }
  .price {
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    &.discount {
      color: #e10600;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
    }
    & > .price-text {
      padding-left: 6px;
      color: #000;
    }
  }
  .price-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #717171;
  }
  .price-total {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #717171;
    padding-top: 3px;
  }
  .price-icon {
    font-size: 16px;
  }
  .price-popover {
    display: inline;
  }

  .icon-app-lightning {
    color: #fdb12e;
    font-size: 16px;
    margin-left: 4px;
  }
  .days {
    color: #000;
    font-size: 13px;
    padding: 0 4px;
  }
  .discount-wrap {
    display: flex;
    align-items: center;
  }
  .discount {
    margin-left: auto;

    &-percent {
      color: white;
      background: #e10600;
      position: relative;
      top: -2px;
      padding: 0px 4px;
      font-size: 12px;
      font-weight: bold;
      margin-right: 10px;
      height: 18px;
      display: inline-flex;
      align-items: center;
      border-radius: 2px;
      &::before {
        content: "";
        position: absolute;
        left: 100%;
        top: 0;
        width: 0;
        height: 0;
        margin-left: -1px;
        border-style: solid;
        border-width: 9px 0 9px 9px;
        border-color: transparent transparent transparent #e10600;
        border-radius: 2px;
      }
    }
    &-price {
      color: #717171;
      font-size: 18px;
      line-height: 23px;
      padding-left: 6px;
    }
  }
  .prepay {
    span {
      display: inline-block;
      font-size: 10px;
      text-transform: uppercase;
      color: white;
      background: #417505;
      border-radius: 2px;
      padding: 1px 3px;
    }
  }

  .cleaningPrice {
    font-size: 13px;
    margin-top: 4px;
    span {
      color: #a6a6a6;
    }
  }
}

.popover-text {
  display: flex;
  flex-direction: column;
  color: #444444;

  p {
    margin: 0;
    padding: 0;
  }

  &__price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
