<template>
  <div class="panorama" id="map">
    <div class="panorama-close" @click="$emit('panoramaClose')">
      <span class="icon-app-cross"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import imgPointHome from '@/assets/img/map/point-home.svg';

export default {
  name: "Panorama",
  props: {
    settings: {
      type: Object,
      default: () => ({})
    },
    coords: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      panorama: null,
      collection: null,
    }
  },
  mounted() {
    const {
      apiKey = '',
      lang = 'ru_RU',
      version = '2.1',
      coordorder = 'latlong',
      debug = false,
    } = this.settings;
    const mode = debug ? 'debug' : 'release';
    const settingsPart = `lang=${lang}${apiKey && `&apikey=${apiKey}`}&mode=${mode}&coordorder=${coordorder}`;
    const link = `https://api-maps.yandex.ru/${version}/?${settingsPart}`;
    const isLoadedScript = document.querySelector(`script[src*="${link}"]`)
    if(isLoadedScript) {
      this.initPanorama()
    } else {
      const yandexMapScript = document.createElement('script');
      yandexMapScript.setAttribute('src', link);
      yandexMapScript.setAttribute('async', '');
      yandexMapScript.setAttribute('defer', '');
      document.body.appendChild(yandexMapScript);
      yandexMapScript.onload = () => {
        ymaps.ready(() => {
          this.initPanorama()
        });
      };
    }
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
  },
  methods: {
    initPanorama() {
      this.panorama = new ymaps.Map('map', {
        center: this.coords,
        zoom: 18,
        type: 'yandex#map',
        controls: []
      })
      this.objectPlacmark = new ymaps.Placemark(
        [this.getObjectData.location.lat, this.getObjectData.location.lng],
        { hintContent: `${this.getObjectData.location.location}, ${this.getObjectData.location.address}` },
        {
          iconLayout: "default#imageWithContent",
          iconImageHref: imgPointHome,
          iconImageSize: [30, 40],
          iconImageOffset: [-15, -40]
        }
      );
      this.panorama.geoObjects.add(this.objectPlacmark);
      let versionYmaps = ymaps.meta.version.replace(/[\.\/]/g, "-");
      this.collection = new ymaps.GeoObjectCollection();
      this.panorama.geoObjects.add(this.collection);
      this.panorama.getPanoramaManager().then((manager) => {
        manager.enableLookup();
        manager.openPlayer(this.panorama.getCenter());
        manager.events.add('openplayer', () => {
          var player = manager.getPlayer();

          // Нужно для того чтобы убрать фулскрин с контролов, чтобы сделать по нормальному нужно убить время и переписать на createPlayer();
          let full = document.querySelector(`.ymaps-${versionYmaps}-panorama-control__fullscreen`);
          let close = document.querySelector(`.ymaps-${versionYmaps}-panorama-control__close`);
          full.style.display = "none";
          close.style.display = "none";

          player.events.add(['panoramachange'], (res) => {
              this.collection.removeAll();
          });
          player.events.add(['destroy'], (res) => {
              this.$emit('panoramaClose');
          });
          player.events.add('markerexpand', function (e) {
              let position = e.get('marker').getPosition(),
                  coords = position.slice(0, 2);
              this.collection.add(new ymaps.Placemark(coords, {}, {
                  openBalloonOnClick: false,
                  iconLayout: 'default#image',
                  iconImageHref: 'circle.png',
                  iconImageSize: [10, 10],
                  iconImageOffset: [-5, -5]
              }));
          });
          player.events.add('markercollapse', (e) => {
            let position = e.get('marker').getPosition(),
                coords = position.slice(0, 2);
            this.collection.each((obj) => {
                if (ymaps.util.math.areEqual(obj.geometry.getCoordinates(), coords)) {
                    this.collection.remove(obj);
                }
            });
          });
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.panorama {
  position: relative;
  height: 100%;

  &-close {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 38px;
    height: 38px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 3px 1px rgba(0,0,0,.2);

    .icon-app-cross {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }
  }
}
</style>
