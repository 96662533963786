<i18n lang="json">
{
	"ru": {
    "added": "Вы добавили объект в Избранное",
    "removed": "Вы удалили объект из Избранного",
    "cancel": "Вернуть",
    "enterFirst": "Войдите в аккаунт, чтобы \nдобавить объект в Избранное",
    "later": "Позже",
    "login": "Войти"
	},
	"en": {
		"added": "You have added an item to your Favorites",
    "removed": "You have removed an item from your Favorites",
    "cancel": "Сancel",
    "enterFirst": "Log in to your account to add an item to Favorites",
    "later": "Later",
    "login": "Login"
	}
}
</i18n>

<template>
  <button
    class="favorite"
    :class="{ active: favorite, light: isMobile}"
    @click.stop.prevent="toggle()"
  >
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 7.08361C2 10.991 6.63926 13.8072 9.00088 15.5003C11.3625 13.8072 16 10.9911 16 7.08375C16 3.17678 11.501 1.00027 9.00168 5.49856C9.00134 5.49917 9.00042 5.49917 9.00008 5.49856C6.49904 1.00046 2 3.17664 2 7.08361Z" stroke="white" fill="" stroke-width="1.6"/>
    </svg>
  </button>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { Objects } from "@/components/api/";

import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { useI18n } from 'vue-i18n';
import sutochnoMetrika from "@/utils/sutochnoMetrika";

const TOASTER_LIFETIME = 3000;

export default {
  name: "BaseFavorite",
  props: {
    id: {
      type: Number,
      required: true,
    },
    isFavorite: {
      type: Boolean,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  inject: ["toaster"],
  data() {
    return {
      isDisabled: false,
      favorite: this.isFavorite,
      toasterTimeout: null
    };
  },
  computed: {
    ...mapState("user", ["viewPort", "favoritesForGuest", "isAuth", "userData"]),
    ...mapGetters("search", ["commonParamsForMetrika", "getObjectPositionByID", "getFavoriteToastId", "getFavoriteToastTimeoutId"]),
  },
  watch: {
    'userData.objects.favorites_ids.length' () {
      if (this.isAuth) {
        this.checkFavorites(this.userData.objects.favorites_ids);
      }
    },
    'favoritesForGuest.length' () {
      if (!this.isAuth) {
        this.checkFavorites(this.favoritesForGuest);
      }
    },
  },
  methods: {
    ...mapActions("user", ["loadUserData"]),
    ...mapActions("user/authModule", ["showModalByOldState"]),
    ...mapMutations("user", ["toggleFavoritesForGuest"]),
    ...mapMutations("search", ["setFavoriteToastId", "setFavoriteToastTimeoutId"]),
    resetFavoriteToast() {
      clearTimeout(this.getFavoriteToastTimeoutId);
      this.setFavoriteToastId(null);
      this.setFavoriteToastTimeoutId(null);
    },
    setFavoriteToast(id) {
      this.setFavoriteToastId(id);
      if (this.getFavoriteToastTimeoutId) {
        clearTimeout(this.getFavoriteToastTimeoutId);
      }
      const favoriteToastTimeoutId = setTimeout(() => {
        this.resetFavoriteToast();
      }, TOASTER_LIFETIME);
      this.setFavoriteToastTimeoutId(favoriteToastTimeoutId);
    },
    pushNoAuthToast() {
      let toastId = null;
      if (!this.getFavoriteToastId) {
        toastId = this.toaster.push({
          title: this.t("enterFirst"),
          icon: {
            name: "lock"
          },
          lifetime: TOASTER_LIFETIME,
          buttons: [
            {
              text: this.t("later"),
              action: () => this.resetFavoriteToast()
            },
            {
              type: "primary",
              text: this.t("login"),
              action: () => {
                this.resetFavoriteToast();
                this.showModalByOldState('auth');
              }
            }
          ]
        });
      } else {
        toastId = this.toaster.change(this.getFavoriteToastId, { lifetime: TOASTER_LIFETIME });
      }
      this.setFavoriteToast(toastId);
    },
    pushFavoriteToast(isFavorite) {
      let toastId = null;
      if (isFavorite) {
        const toasterTemplate = {
          icon: {
            name: "heart-tile-fill",
            color: "#F51449"
          },
          title: this.t("added"),
          buttons: [],
          lifetime: TOASTER_LIFETIME
        };
        toastId = this.getFavoriteToastId ?
          this.toaster.change(this.getFavoriteToastId, toasterTemplate) :
          this.toaster.push(toasterTemplate);
      } else {
        const toasterTemplate = {
          title: this.t("removed"),
          icon: {
            name: "heart-tile"
          },
          buttons: [
            {
              type: "primary",
              text: this.t("cancel"),
              action: () => {
                this.resetFavoriteToast();
                this.toggle(false);
              }
            }
          ],
          lifetime: TOASTER_LIFETIME
        };
        toastId = this.getFavoriteToastId ?
          this.toaster.change(this.getFavoriteToastId, toasterTemplate) :
          this.toaster.push(toasterTemplate);
      }
      this.setFavoriteToast(toastId);
    },
    toggle(viewToast = true) {
      if (this.isDisabled) {
        return;
      }

      if (!this.isAuth) {
        this.pushNoAuthToast();
        return;
      }

      this.isDisabled = true;

      let newState = !this.favorite;
      this.favorite = newState;

      Objects.toggleFavorite(this.id)
        .then((response) => {
          newState = Boolean(response?.data?.data?.is_favorite);
          this.favorite = newState;
          if (this.favorite) {
            sendEventGtag(
              this.viewPort === "mobile" ? "search_sa_m" : "search_sa",
              {
                click: "add2favorite",
              }
            );
          }
          let screenBlock = this.favorite
            ? "object_favorite"
            : "object_unfavorite";
          sutochnoMetrika.search('click', screenBlock, {
            ...this.commonParamsForMetrika,
            objectIdsArray: [this.id],
            objectPosition: this.getObjectPositionByID(this.id),
            page: "search",
          })
          setTimeout(() => {
            this.loadUserData();
          }, 0);
          this.$emit("favoriteState", this.favorite);
          this.isDisabled = false;

          if (!viewToast) {
            return;
          }
          this.pushFavoriteToast(newState);
        })
        .catch((error) => {
          this.favorite = !newState;
          this.$emit("favoriteState", this.favorite);
          console.warn("Ajax error: not toggle favorite, ", error);
        });
    },
    setFavorite(){
      if (!this.isAuth) {
        return;
      }
      const favoriteIds = this.userData.objects.favorites_ids;
      this.checkFavorites(favoriteIds);
    },
    checkFavorites (favoritesIds) {
      const favorite = favoritesIds.includes(this.id.toString());
      if (this.favorite === favorite) {
        return;
      }
      this.favorite = favorite;
    }
  },
  created(){
    this.setFavorite();
  }
};
</script>
<style lang="scss" scoped>
.favorite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  padding: 0;
  border: none;
  transition: background-color 0.3s;
  svg {
    width: 18px;
    height: 18px;
    transition: 0.15s;
  }
  path {
    transition: 0.3s;
  }
  &:not(.active):active {
    svg {
      transform: scale(0.8);
    }
  }
  &.active {
    background-color: #F51449;
    path {
      fill: #fff;
    }
  }
  &-icon {
    font-size: 15px;
    color: #fff;
  }
}
.light.favorite{
  width: 18px;
  height: 18px;
  background: inherit;
  backdrop-filter: none;
  transform: scale(1.2);
  &.active {
    background: inherit;
    path {
      fill: #f51449;
      stroke: #f51449;
    }
  }
}
</style>
