<i18n lang="json">
{
	"ru": {
        "recommend": "Гости рекомендуют",
        "seeReviews": "Читать отзывы гостей",
        "getThere": "Как добраться"
	},
	"en": {
        "recommend": "Guest's choice",
        "seeReviews": "Read guest reviews",
        "getThere": "How to get there"
	}
}
</i18n>

<template>
  <div
    class="card-map"
    :class="{ 'disabled-link': isDisabledLink }"
    @click="clickObjectWrap"
  >
    <div class="card-map__img">
      <Img :linkBronevik="linkBronevik" :data="objectData" />
    </div>
    <Content
      :linkBronevik="linkBronevik"
      :isDisabledLink="isDisabledLink"
      :data="objectData"
      :show-detail="showDetail"
    />
    <Prices
      :linkBronevik="linkBronevik"
      :data="objectData"
      :show-detail="showDetail"
    />
    <div @click="showPath" class="link-path">
      {{ t("getThere") }}
    </div>
    <ObjectDetail
      v-if="showDetail"
      :id="String(objectData.id)"
      :show-detail="showPage"
    />
    <LoadingPrice v-if="bronevikLoadingPrice" />
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { documentBody } from "@sutochno/utils";

import Img from "./Img";
import Content from "./Content";
import Prices from "./Prices";
import ObjectDetail from "./ObjectDetail";
import LoadingPrice from "@/components/base/LoadingPrice.vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "ObjectBlock",
  components: {
    Img,
    Content,
    Prices,
    ObjectDetail,
    LoadingPrice,
  },
  props: {
    objectData: {
      type: Object,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      showDetail: false,
      hoveredTop: false,
      bronevikLoadingPrice: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("onRender", this.$el.getBoundingClientRect());
      this.setObjectMap(this.objectData);
    });
  },
  computed: {
    ...mapGetters("search", ["occupied", "getQuery", "maxGuests"]),
    ...mapState("search", [
      "objectsBronevik",
      "pricesBronevik",
      "loadBronevikPrice",
    ]),
    conveniencesList() {
      if (!this.objectData.conveniences) return [];
      return Object.entries(this.objectData.conveniences)
        .filter(([key, value]) => value && key)
        .map(([key]) => key);
    },
    dataBronevik() {
      let idBronevik = this.objectsBronevik.find(
        (item) => item == this.objectData.id
      );
      if (idBronevik) {
        return this.pricesBronevik &&
          this.pricesBronevik[idBronevik] &&
          this.pricesBronevik[idBronevik]["prices"]
          ? this.pricesBronevik[idBronevik]["prices"]
          : [];
      } else {
        return [];
      }
    },
    linkBronevik() {
      if (this.dataBronevik.length) {
        return this.dataBronevik[0].link;
      } else {
        return "";
      }
    },
    isDisabledLink() {
      let idBronevik = this.objectsBronevik.find(
        (item) => item == this.objectData.id
      );
      if (idBronevik) {
        if (this.dataBronevik.length) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations("detail", ["setShowPath", "setObjectMap"]),
    showPath() {
      this.$store.commit("search/setCardVisible", false);
      this.setShowPath(true);
      this.setObjectMap(this.objectData);
    },
    showPage() {
      this.showDetail = !this.showDetail;
      documentBody.overflow("hidden");
      return this.showDetail;
    },
    mouseOver() {
      this.hoveredTop = true;
    },
    mouseOut() {
      this.hoveredTop = false;
    },
    clickObjectWrap(event) {
      if (this.isDisabledLink) {
        event.preventDefault();
        if (this.occupied) {
          sendEventGtag("search_sa", {
            click: "object_b_nolink",
            value: this.objectData.location.city.title,
          });
        }
      }
      if (this.loadBronevikPrice) {
        event.preventDefault();
        this.bronevikLoadingPrice = true;
      }
    },
  },
  watch: {
    objectData() {
      this.$nextTick(() =>
        this.$emit("onRender", this.$el.getBoundingClientRect())
      );
    },
    loadBronevikPrice(val) {
      if (!val) {
        if (this.bronevikLoadingPrice) {
          this.bronevikLoadingPrice = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card-map {
  position: absolute;
  z-index: 1;
  width: auto;
  border: none;
  margin: 0px 0 20px 0;
  display: grid;
  grid-template-areas:
    "carousel"
    "content"
    "prices";
  grid-template-rows: 180px auto auto;
  grid-template-columns: 270px;
  // animation: fadein 0.8s;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  user-select: none;
  background-color: #fff;
  // transition: top 0.5s, left 0.5s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__sticker {
    grid-area: top;
  }

  &__link {
    color: inherit;
  }
  &__sticker-text {
    background: #d8d8d8;
    border-radius: 2px 2px 0px 0px;
    font-size: 13px;
    line-height: 17px;
    padding: 3px 10px;
    color: inherit;
  }

  .card-map__img {
    grid-area: carousel;
  }
  .object-content {
    grid-area: content;
  }
  :deep(.object-prices) {
    grid-area: prices;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
  }
  &.disabled-link {
    a,
    :deep(.carousel > a) {
      pointer-events: none;
    }
    a {
      cursor: default;
    }
    :deep() {
      .price-bronevik,
      .price-order {
        opacity: 0.7;
      }
    }
  }
  :deep(p) {
    margin-top: 0;
  }
}

.link-path {
  padding: 7px 10px;
  margin: 0 15px;
  margin-bottom: 15px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  cursor: pointer;
  width: max-content;
}
</style>
