<i18n>
{
  "ru": {
    "distance_m": "~ {n} м",
    "distance_km": "~ {n} км"
  },
  "en": {
    "distance_m": "~ {n} m",
    "distance_km": "~ {n} km"
  }
}
</i18n>
<template>
  <div
    v-for="(item, index) in listPoints"
    :key="item.id"
    class="map-point"
    :class="{ active: activePoint === index }"
  >
    <div class="map-point--name">
      {{ item.title }}
    </div>
    <div class="map-point--distance" @click="setRoute(item, 'auto', index)">
      {{ getDistance(item.distance) }}
    </div>
    <div class="map-point--close" @click="removePoint()">
      <span class="icon-app-close-btn"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "MapSelectPoint",
  props: {
    listPoints: {
      type: Array,
      default: () => [],
    },
    indexSights: {
      type: Number,
    },
    activePoint: {
      type: Number,
    },
    modalMapView: {
      type: Boolean,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    }
  },
  computed: {
    ...mapState("user", ["viewPort"]),
    ...mapGetters("detail", ["getObjectData"]),
    ...mapState("detail", ["locationObject"]),
  },
  methods: {
    ...mapMutations("detail", ["routeMapViewMutate"]),
    getDistance(val) {
      if (!val) {
        return "";
      }
      if (val <= 100) {
        return this.t("distance_m", { n: Math.ceil(val / 10) * 10 });
      } else if (val <= 500) {
        return this.t("distance_m", { n: Math.ceil(val / 50) * 50 });
      } else if (val <= 900) {
        return this.t("distance_m", { n: Math.ceil(val / 100) * 100 });
      } else if (val <= 9900) {
        return this.t("distance_km", { n: (Math.ceil(val / 100) * 100) / 1000 }).replace(".", ",");
      } else if (val > 9901) {
        return this.t("distance_km", { n: (Math.ceil(val / 1000) * 1000) / 1000 }).replace(".", ",");
      }
    },
    setRoute(to, routingMode, index) {
      let from = [];
      if (this.getObjectData.location.lat && this.getObjectData.location.lng) {
        from = [
          this.getObjectData.location.lat,
          this.getObjectData.location.lng,
        ];
      }

      let pointB = "";
      if (!to.lat || !to.lng) {
        pointB = `${this.locationObject.regionTitle}, ${this.getObjectData.location.location}, ${to.title}`;
      } else {
        pointB = [to.lat, to.lng];
      }

      if (to.typeId === 485) {
        // жд вокзал
        this.$gtag.event("build_route", { click: "train" });
      } else if (to.typeId === 746) {
        // аеропорт
        this.$gtag.event("build_route", { click: "airport" });
      }

      this.$emit("setRoute", {
        from: from,
        to: pointB,
        routingMode: routingMode,
      });
      this.$emit("setActivePoint", index);
    },
    removePoint() {
      this.routeMapViewMutate(false);
      this.$emit("setActivePoint", undefined);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-point {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 21px;
  padding: 4px 0;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 100%;

  &.active {
    padding: 8px 0 8px 15px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: #f6f6f6;

    .modal-wrap-list & {
      .mobile & {
        padding: 2px 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: #fff;
        .map-point--close {
          display: none;
        }
      }
    }

    .map-point--name {
      max-width: 260px;
      .sc-modal-body &,
      .mobile & {
        max-width: 210px;
      }
    }
    .map-point--close {
      right: 10px;
    }
    .map-point--distance {
      .mobile & {
        color: #f51449;
      }
    }
  }

  .modal-wrap-list & {
    padding: 2px 0;
  }

  &--name {
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--distance {
    cursor: pointer;
    color: #2d6cb4;
    margin-right: 5px;
    white-space: nowrap;
  }

  &--close {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: -24px;
    transform: translateY(-50%);
    transition: right 0.3s ease;

    .modal-wrap-list & {
      margin-top: 2px;
    }

    .icon-app-close-btn {
      color: #444444;
      font-size: 15px;
      vertical-align: bottom;
    }
  }
}
</style>
