<i18n lang="json">
{
  "ru": {
    "guestChoice":"Выбор гостей",
    "guests": "Гости",
    "adult": "0|{n} взрослый | {n} взрослых | {n} взрослых",
    "guest": "0|{n} гость | {n} гостя | {n} гостей",
    "child": " без детей |{n} ребёнок | {n} ребёнка | {n} ребёнка",
    "children": "{n} детей",
    "ok": "Сохранить",
    "addChild":"Добавить ребёнка",
    "withPets": "питомец"
  },
  "en": {
    "guestChoice":"Guest Choice",
    "guests": "Guests",
    "adult": "0|{n} adult | {n} adult | {n} adult",
    "guest": "0|{n} guest | {n} guests | {n} guests",
    "child": " without children |{n} child | {n} child's | {n} child's",
    "children": "{n} children's",
    "ok": "Save",
    "addChild":"Add child",
    "withPets": "pet"
  }
}
</i18n>

<template>
  <div class="select-guests" :class="view">
    <button
      class="select-guests--btn"
      :class="{
        merge: isMerge,
        active: isMerge && maxGuests > 1,
        radius: isNewStyle,
      }"
      @click="showModal"
    >
      <template v-if="isMerge">
        <div>{{ t("guest", maxGuests) }}</div>
      </template>

      <template v-else>
        <div v-if="$router.currentRoute.value.name == 'Main'" class="title">
          {{ t("guests") }}
        </div>
        <div class="body">
          {{ getGuestsText }}
          <template v-if="!guests.childrens.length && !guests.pets.value">
            <span class="empty">{{ t("child", 0) }}</span>
          </template>
        </div>
        <span class="ico-arrow"></span>
      </template>
    </button>

    <Modal
      ref="modalRef"
      v-model="isGuestsModal"
      class="custom-modal"
      :title="t('guestChoice')"
      :is-animate-modal="isAnimateModal"
      :v-show="true"
      @close="closeModal()"
    >
      <div class="custom-modal__main">
        <LightGuestOptions
          :guests="getLocalGuestsData"
          @changeChildren="changeChildren"
          @changeAdults="changeAdults"
          @changePetsValue="changePetsValue"
        />
      </div>

      <template #footer>
        <button
          class="button button_size_md button_dark-gray button_w-100"
          @click="apply"
        >
          {{ btnTitle ? btnTitle : t("ok") }}
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import SelectMain from "./SelectMain";
import ChildrenSelect from "./ChildrenSelect";
import GuestsCounter from "./GuestsCounter";
import LightGuestOptions from "./LightGuestOptions";
import Modal from "@sutochno/modal";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "SelectGuestsMobile",
  components: {
    Modal,
    SelectMain,
    ChildrenSelect,
    GuestsCounter,
    LightGuestOptions
  },
  props: {
    view: {
      type: String,
    },
    maxGuestsObject: {
      type: Number,
    },
    localGuests: {
      type: Object,
    },
    isMerge: {
      type: Boolean,
      default: false,
    },
    btnTitle: {
      type: String,
      default: null,
    },
    isBlockingClick: {
      type: Boolean,
      default: false,
    },
    isAnimateModal: {
      default: true,
      type: Boolean,
    },
    isNewStyle: {
      // новый стиль, задача VUECLIENT-1130
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data(){
    return{
      selectGuests: null,
      countLocalGuests: null,
      isGuestsModal: false,
      adults: 1,
      children: [],
      petsValue: false,
    }
  },
  computed: {
    ...mapState("search", ["guests"]),
    ...mapGetters("hotel", ["guestsNum"]),
    ...mapGetters("search", ["maxGuests"]),
    isHasChanges(){
      if (this.adults !== this.guests.adults){
        return true;
      }
      if (
        JSON.stringify(this.children) !== JSON.stringify(this.guests.childrens)
      ){
        return true;
      }
      if (this.petsValue !== this.guests.pets?.value) {
        return true;
      }
      return false;
    },
    getGuestsText(){
      let result = this.t("adult", this.guests.adults);
      if (this.guests.childrens.length){
        if (this.guests.childrens.length >= 5){
          result += ", " + this.t("children", this.guests.childrens.length);
        } else {
          result += ", " + this.t("child", this.guests.childrens.length)
        }
      }
      if (this.guests?.pets?.value){
        result += ", " + this.t("withPets");
      }

      return result;
    },
    getLocalGuestsData(){
      return {
        adults: this.adults,
        childrens: this.children,
        pets: {
          value: this.petsValue
        }
      }
    },
  },
  mounted(){
    this.$nextTick(()=>{
      this.adults = this.guests.adults;
      this.children = this.guests.childrens;
      this.petsValue = this.guests?.pets?.value;
    })
  },
  watch: {
    isGuestsModal(val) {
      if (this.view == "page-detail" || this.view == "page-hotel") {
        // хак для ios, модалка в модалке не корректно работают
        this.setModalsToggle({
          name: "guestsDetail",
          val: val,
        });
      }
    },
  },
  methods: {
    ...mapMutations("application", ["setModalsToggle"]),
    ...mapMutations("search", ["setChildrenWithoutAgeError"]),
    ...mapActions("search", ["updateAdults", "updateChildrens"]),
    closeModal() {
      this.$emit("guestsClose");
    },
    showModal() {
      this.$emit("clickGuests");
      if (!this.isBlockingClick) this.$refs.modalRef.show();
      this.$emit("click");
    },
    hideModal() {
      this.$refs.modalRef.close();
    },
    apply() {
      if (this.children.includes(-1)) {
        this.setChildrenWithoutAgeError(true);
        return false
      }
      this.setChildrenWithoutAgeError(false);
      if (this.isHasChanges) {
        this.sendToStore();
      }
      this.$refs.modalRef.close();
    },
    sendToStore() {
      this.$store.dispatch("search/updatePets", {
        value: this.petsValue,
        description: this.guests?.pets?.description || "",
      });
      this.$store.dispatch("search/updateAdults", this.adults);
      this.$store.dispatch("search/updateChildrens", this.children);
    },
    changeChildren(val) {
      this.children = val;
    },
    changeAdults(val) {
      this.adults = val;
    },
    changePetsValue(val) {
      this.petsValue = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-ok {
  height: 42px;
}
.guests {
  &__item {
    margin-top: 15px;
  }
}
.select-guests {
  max-width: 100%;
  margin-bottom: 10px;

  &__body {
    padding: 20px;
    z-index: 1;
    position: relative;
  }

  &--fade-enter-active,
  &--fade-leave-active {
    opacity: 1;
    transition: opacity 0.15s ease;
  }
  &--fade-enter,
  &--fade-leave-to {
    opacity: 0;
  }
  &--btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    margin: 0;
    outline-style: none;
    background: #fff;
    font-size: 16px;
    line-height: 1;
    color: #444444;
    border: none;
    height: 60px;
    width: 100%;
    border-radius: 2px;
    padding: 0 30px 0 10px;
    position: relative;
    box-shadow: inset 0 0 0 1px #fff;

    &.radius{
      border-radius: 50px;
    }

    .title {
      font-size: 14px;
      opacity: 0.5;
      margin-bottom: 4px;
    }
    .body {
      span {
        &.empty {
          color: #717171;
        }
      }
    }
    .ico-arrow {
      display: block;
      width: 12px;
      height: 6px;
      position: absolute;
      top: 50%;
      right: 24px;
      margin-top: -3px;
      transform: rotate(0deg);
      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: #717171;
        height: 1px;
        width: 8px;
        margin: 2px 0 0 0;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
        margin: 2px 0 0 5px;
      }
    }
  }
  &.page-search {
    .select-guests--btn {
      &.merge {
        height: 32px;
        @media screen and (max-width: 560px){
          height: 42px;
        }
        padding: 0 15px;
        color: #000;
        border: 1px solid #d1d1d1;
        &.active {
          border: 1px solid #000;
        }
      }
    }
  }
  &.page-search,
  &.page-hotel {
    max-width: max-content;
    margin-bottom: 0;
    .select-guests--btn {
      height: 36px;
      font-size: 14px;
      border: 1px solid #d1d1d1;
      border-radius: 3px;
      white-space: nowrap;
      &.radius{
        border-radius: 50px;
      }
      .ico-arrow {
        right: 10px;
      }
    }
  }
  &.page-hotel {
    // margin-left: 2px;
    max-width: max-content;
    .select-guests--btn {
      height: 36px;
      padding: 0 26px 0 12px;
      font-size: 14px;
      min-width: 80px;
      .ico-arrow {
        right: 8px;
      }
    }
  }
  &.page-detail {
    .select-guests--btn {
      height: 36px;
      border: 1px solid #d1d1d1;
      border-radius: 3px;
      font-size: 14px;
      padding: 0 24px 0 14px;
      .ico-arrow {
        right: 12px;
      }
    }
  }
}
.custom-modal {
  &__main{
    padding: 20px 20px;
  }
  :deep(.sc-modal-footer) {
    padding: 10px 10px calc(10px + env(safe-area-inset-bottom));
  }
}
</style>
