import { useI18n } from "vue-i18n";
import { formatPrice } from "@/utils/format";

export const useProcessData = () => {
  const { t, locale } = useI18n();

  const processDataParking = (
    key,
    value,
    currency = "RUB",
    isHotel = false
  ) => {
    if (!value?.properties) {
      return false;
    }
    const currentProperties = JSON.parse(JSON.stringify(value.properties));
    // все свойства парковки
    const propertyFields = [
      isHotel ? "parking" : "p_parking",
      "p_price_type",
      "is_p_covered",
      "is_p_private",
      "is_p_fenced",
      "is_p_video_surveilla",
      "is_p_security",
      "is_p_on_site",
      "distance_to_p",
      "parking_cost_1",
      "p_price_type",
      "is_p_booking",
    ];
    const properties = {};
    for (const property of propertyFields) {
      const prop = currentProperties.find((prop) => prop.name === property);
      if (prop && typeof prop.value !== "undefined" && prop.value !== null) {
        properties[property] = prop;
      }
    }

    const parkingEnum = { p_paid: 1, p_free: 2, p_no: 3 };
    const p_price_type = properties["p_price_type"];
    const distance_to_p = properties["distance_to_p"];

    if (isHotel && properties["parking"]) {
      properties["parking"].value =
        parkingEnum[properties["parking"].value] || null;
    }
    const parking = (isHotel ? properties["parking"] : properties["p_parking"]) ?? {};

    if (parking.value === 3) {
      return {
        title: value.title,
        key: key,
        properties: [
          {
            name: parking.name,
            value: parking.value,
            title: t("parking.parking.3"),
          },
        ],
      };
    } else if (parking.value && parking.value !== 1) {
      delete properties["is_p_booking"];
    }

    const actions = {
      p_parking: (property) => property,
      is_p_on_site: (property) => ({
        ...property,
        title: t(
          `parking.is_p_on_site.${property?.value}`,
          distance_to_p?.value
            ? {
                distance: `(${t(
                  `parking.distance_to_p.${distance_to_p.value}`
                )})`,
              }
            : {}
        ),
      }),
      parking_cost_1: (property) => {
        const cost = formatPrice(property.value, currency, locale.value);
        if (
          typeof p_price_type?.value !== "undefined" &&
          p_price_type?.value !== null
        ) {
          return {
            ...property,
            title: t(`parking.parking_cost_1.${p_price_type.value}`, { cost }),
          };
        }
        return {
          ...property,
          title: t(`parking.parking_cost_1.default`, { cost }),
        };
      },
      default: (property) => {
        return {
          ...property,
          title: t(`parking.${property.name}.${property.value}`),
        };
      },
    };

    const properties2 = Object.values(properties)
      .filter((property) => {
        if (property.name === "parking_cost_1") {
          return parking.value === 1;
        }

        return (
          property.value !== -1 &&
          property.name !== "parking" &&
          property.name !== "p_price_type" &&
          property.name !== "distance_to_p"
        );
      })
      .map((property) =>
        actions[property.name]
          ? actions[property.name](property)
          : actions["default"](property)
      );

    const label =
      typeof parking?.value !== "undefined" && parking?.value !== null
        ? t(`parking.parking.${parking.value}`)
        : "";

    return {
      title: value.title,
      key: key,
      properties: properties2,
      label: label,
    };
  };

  return {
    processDataParking,
  };
};
