<i18n lang="json">
{
  "ru": {
    "adult": "0|{n} взрослый | {n} взрослых | {n} взрослых",
    "child": " без детей | {n} ребёнок | {n} ребёнка | {n} ребёнка",
    "children": "{n} детей",
    "pets": "Питомцы",
    "withPets": "питомец",
    "done":"Готово",
    "errorText": "Укажите с каким животным вы путешествуете"
  },
  "en": {
    "adult": "0|{n} adult | {n} adult | {n} adult",
    "child": " without children |{n} child | {n} child's | {n} child's",
    "children": "{n} children's",
    "pets": "With animals",
    "withPets": "pet",
    "done":"Done",
    "errorText": "Please indicate which animal you are traveling with"
  }
}
</i18n>

<template>
  <div v-click-outside="close" class="select-guests">
    <button
      class="select-guests--btn"
      :class="{ 'active-simple': isOpenGuestsSelect }"
      @click="showSelect()"
    >
      <div class="body">
        {{ getGuestsText }}
        <span v-if="!this.guests.childrens.length && !this.guests.pets.value" class="empty">
          {{ t("child", 0) }}
        </span>
      </div>
      <span class="ico-arrow icon-app-select"></span>
    </button>
    <transition name="select-guests--fade">
      <div
        class="select-guests__main tmp-font--medium"
        id="sel"
        ref="selectGuests"
        v-if="isOpenGuestsSelect"
      >
        <LightGuestOptions
          :guests="{
            adults,
            childrens: children,
            pets: {
              value: petsValue,
            }
          }"
          @changeChildren="changeChildren"
          @changeAdults="changeAdults"
          @changePetsValue="changePetsValue"
        />
        <hr>
        <UIButton @click="save()" buttonTheme="dark">
          {{ t("done") }}
        </UIButton>
      </div>
    </transition>
  </div>
</template>

<script>
import LightGuestOptions from "@/components/base/SelectGuestsCopy/LightGuestOptions";
import UIButton from "@/components/UIKit/UIButton"
import { mapState, mapMutations } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "SelectGuestsDesktop",
  components: {
    LightGuestOptions,
    UIButton
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isOpenGuestsSelect: false,
      adults: 1,
      children: [],
      petsValue: false,
    };
  },
  props: {
    searchActive: {
      type: Boolean,
      default: false,
    },
  },
  watch:{
    "guests.pets"(){
      this.petsValue = this.guests?.pets?.value || false;
    }
  },
  computed: {
    ...mapState("search", ["guests"]),
    getGuestsText() {
      let result = this.t("adult", this.guests.adults);
      if (this.guests.childrens.length) {
        let children =
          this.guests.childrens.length >= 5
            ? this.t("children", { n: this.guests.childrens.length })
            : this.t("child", this.guests.childrens.length);
        result += ", " + children;

        if (this.guests.pets.value) {
          result += ", ..."
        }
      }

      if (!this.guests.childrens.length && this.guests.pets.value) {
        result += ", " + this.t("withPets");
      }
      return result;
    },
  },
  created() {
    this.$nextTick(()=>{
      this.adults = this.guests?.adults || 1;
      this.children = this.guests?.childrens || [];
      this.petsValue = this.guests?.pets?.value || false;
    })
  },
  methods: {
    ...mapMutations("search", ["setChildrenWithoutAgeError"]),
    showSelect() {
      this.isOpenGuestsSelect = !this.isOpenGuestsSelect;
      this.$emit("clickGuests");
    },
    close() {
      this.isOpenGuestsSelect = false;
      if (this.isChangeByClose && this.isHasChanges) {
        this.sendToStore();
      }
    },
    save() {
      if (this.children.includes(-1)) {
        this.setChildrenWithoutAgeError(true);
        return;
      }
      this.setChildrenWithoutAgeError(false);
      this.sendToStore()
      this.isOpenGuestsSelect = false;
    },
    sendToStore() {
      this.$store.dispatch("search/updatePets", {
        value: this.petsValue,
        description: this.guests?.pets?.description || "",
      });
      this.$store.dispatch("search/updateAdults", this.adults);
      this.$store.dispatch("search/updateChildrens", this.children);
    },
    changeChildren(val) {
      this.children = val;
    },
    changeAdults(val) {
      this.adults = val;
    },
    changePetsValue(val) {
      this.petsValue = val;
    },
  },

};
</script>

<style lang="scss" scoped>
.select-guests {
  position: relative;
  width: 100%;
  height: 100%;
  &--fade-enter-active,
  &--fade-leave-active {
    opacity: 1;
    transition: opacity 0.15s ease;
  }
  &--fade-enter,
  &--fade-leave-to {
    opacity: 0;
  }

  &--btn {
    padding: 12px 46px 12px 20px;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0;
    outline: none;
    border: none;
    white-space: nowrap;
    background: transparent;
    border:1px solid #000;
    border-radius: 3px;
    height: 42px;
    min-width: 205px;
    
    .body {
      width: 100%;
      text-align: left;
      span {
        &.empty {
          color: #717171;
          padding-right: 10px;
        }
      }
    }
    &.active-simple {
      .ico-arrow {
        transform: translateY(-50%) scaleY(-1);
      }
    }
  }
  &__main {
    padding: 15px 20px 20px;
    position: absolute;
    top: 100%;
    z-index: 4;
    width: 100%;
    max-height: 600px;
    margin-top: 4px;
    background: #FFFFFF;
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.08), 0px 4px 15px rgba(20, 45, 136, 0.06);
    border-radius: 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .select-guests--main {
    margin-left: 0;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
}
.error{
  border: 1px solid #d12d1e;
  animation: blink 0.4s ease-in-out 4 alternate;
}
.error-text{
  margin-top: 5px;
  color: #D12D1E;
  font-size: 12px;
  line-height: 16px;
}
@keyframes blink {
  from {
    background-color: #fff;
  }
  to {
    background-color: #ffe8e8;
  }
}
::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  background: transparent;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px grey;
  border: 3px solid transparent;
}
.ico-arrow {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
  font-size: 14px;
}
</style>

