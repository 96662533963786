import { useStore } from "vuex";
import useStoreWatchers from "../../store/useStoreWatchers";

export default () => {
  const store = useStore();

  useStoreWatchers([
    store.watch(
      () => store.getters["search/getQuery"],
      (opt) => {
        navigateTo(opt)
      }
    )
  ]);
};