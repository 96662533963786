<i18n>
{
  "ru": {
    "otherPhotos": "Еще {n} фото",
  },
  "en": {
    "otherPhotos": "{n} more photos",
  }
}
</i18n>

<template>
  <div class="slider">
    <Swiper
      class="swiper"
      :modules="modules"
      :pagination="{ clickable: false }"
      navigation
    >
      <SwiperSlide
        class="swiper__slide"
        v-for="(media, index) of mediaSlider.media"
        :key="index"
      >
        <div
          class="slider__last-slide"
          v-if="mediaSlider.otherPhotosAvailable && index + 1 === countSlides"
          v-observe-visibility="{
            callback: visibilityLastSlide,
            intersection: {
              threshold: 0.001,
            },
          }"
        >
          <img class="slider__icon" src="@/assets/img/photo.svg" alt="">
          <span class="slider__text">{{
            t("otherPhotos", mediaSlider.countOtherPhotos)
          }}</span>
        </div>
        <img class="slider__img" :src="media" alt="">
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup>
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const modules = [Pagination, Navigation];

const props = defineProps({
  media: {
    type: Array,
    default: () => []
  },
  countSlides: {
    type: Number,
    default: 5
  }
})
const emits = defineEmits(["visibilityLastSlide"]);

const { t } = useI18n()

const mediaSlider = computed(() => {
  if (props.media.length > props.countSlides) {
    return {
      media: props.media.slice(0, props.countSlides),
      otherPhotosAvailable: true,
      countOtherPhotos: props.media.slice(props.countSlides).length
    }
  }
  return {
    media: props.media,
    otherPhotosAvailable: false
  }
})

function visibilityLastSlide(value){
  emits("visibilityLastSlide", value)
}
</script>

<style lang="scss" scoped>
.slider, .swiper{
  width: 100%;
  height: 100%;
  position: relative;
}

.slider{
  &__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__last-slide{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  &__icon{
    margin-bottom: 4px;
  }
}
:deep(.swiper-button-prev), :deep(.swiper-button-next){
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  transition: opacity .3s;
  z-index: 2;
  cursor: pointer;
  opacity: 0;

  &::after{
    content: url("@/assets/img/arrow-white.svg");
    position: absolute;
    bottom: 41px;
    left: 50%;
    transform: translate(-50%) rotate(180deg);
    width: 18px;
    height: 18px;
  }

  &:hover{
    opacity: 1;
  }
}
:deep(.swiper-button-next){
  left: auto;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);

  &::after{
    transform: translate(-50%);
  }
}
:deep(.swiper-button-disabled){
  &::after{
    opacity: .5;
  }
}
:deep(.swiper-pagination){
  bottom: 8px;

  .swiper-pagination-bullet{
    margin: 0 2px;
    background: rgba(255, 255, 255, 0.6);
    width: 4px;
    height: 4px;
  }
  .swiper-pagination-bullet-active{
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 1);
  }
}
</style>
