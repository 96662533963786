<i18n lang="json">
{
  "ru": {
    "cleaningPrice": "за уборку",
    "orderInstant": "Свободно",
    "orderMore": "Подробнее",
    "orderRequest": "По запросу",
    "hintTextMb": "Доступно мгновенное бронирование на ваши даты",
    "hintText": "Бронирование будет возможно только после того, как хозяин подтвердит доступность и предложит забронировать жильё",
    "hintTextNoDate": "Для уточнения стоимости выберите даты и количество гостей"
  },
  "en": {
    "cleaningPrice": "for cleaning",
    "orderInstant": "Free",
    "orderMore": "More details",
    "orderRequest": "On request",
    "hintTextMb": "Instant booking available for your dates",
    "hintText": "Booking will be possible only after the owner confirms availability and offers to book accommodation",
    "hintTextNoDate": "To specify the cost, select the dates and number of guests"
  }
}
</i18n>
<template>
  <router-link class="link" target="_blank" :to="href">
    <div
      :class="{ 'hint-popup hint-popup__right': screenSize == 'desktop' }"
      :data-hint="
        isInstant
          ? t('hintTextMb')
          : isDate
          ? t('hintText')
          : t('hintTextNoDate')
      "
    >
      <button
        class="btn btn-primary"
        :class="{
          'btn-object-more': color == 'white',
          'btn-l': type == 'l',
          'btn-m': type == 'm',
          'btn-s': type == 's',
        }"
      >
        <!--
        Отображение различных вариантов кнопок должно соответствовать следующей логике:
          "Подробнее" - отображается, если не выбраны даты;
          "Свободно" - выбраны даты и (объект МБ или ГП или Без предоплаты);
          "По запросу" - в остальных случаях, если даты выбраны.
        -->
        <template v-if="isInstant && isDate">
          <span class="icon-button icon-app-lightning"></span>
          {{ t("orderInstant") }}
        </template>
        <template v-else-if="isDate">{{ t("orderRequest") }}</template>
        <template v-else>{{ t("orderMore") }}</template>
      </button>
    </div>
  </router-link>
</template>
<script>
import { mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "BaseButtonOrder",
  props: {
    isInstant: {
      type: Boolean,
      required: true,
    },
    isDate: {
      type: Boolean,
      required: true,
    },
    href: {
      required: true,
    },
    color: {
      type: String,
      default: "blue",
      validator: function (value) {
        return ["blue", "white"].indexOf(value) !== -1;
      },
    },
    type: {
      type: String,
      default: "l",
      validator: function (value) {
        return ["l", "m", "s"].indexOf(value) !== -1;
      },
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapState({
      screenSize: (state) => state.user.viewPort,
    }),
  },
};
</script>
<style lang="scss" scoped>
.btn {
  width: 100%;
}
.btn.btn-primary {
  white-space: nowrap;
}
.btn-s {
  height: 34px;
  line-height: 17px;
  .icon-button {
    font-size: 14px;
    position: relative;
    top: 2px;
  }
}
.btn-l {
  height: 40px;
  line-height: 24px;
  .icon-button {
    font-size: 16px;
    position: relative;
    top: 2px;
  }
}
</style>
